import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./Showroom.scss";
import { Menu, Dropdown, Button } from "antd";
import { useHistory } from "react-router";
import "antd/dist/antd.css";
import ProductCut from "./ProductCut";
import ModelCut from "./ModelCut";
import ModelCutDetails from "./ModelCutDetails";
import Footer from "./Footer";
import queryString from "query-string";
import axios from "axios";
import Logout from "../authentication/Logout/Logout";
import TokenVerification from "../authentication/TokenVerification/TokenVerification";
const { SubMenu } = Menu;

function Showroom(props) {
  // const [visible, setVisible] = useState(false);
  const history = useHistory();
  let queries = queryString.parse(props.location.search);

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(queries.brandId);
  const [brandDetails, setBrandDetails] = useState();
  const [seasonDetails, setSeasonDetails] = useState({ mainBannerImages: "" });
  const [modelDetails, setModelDetails] = useState();
  const [modelResult, setModelResult] = useState();
  const [productDetails, setProductDetails] = useState();
  const [categoryList, setCategoryList] = useState();
  const wrapperRef = useRef();

  const [detailIndex, setDetailIndex] = useState(0);
  const [isOne, setIsOne] = useState(false);

  const [footerShow, setFooterShow] = useState(false);

  const scrollToTop = () => {
    //console.log("ㅇㅇㅇ");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {brands?.map((brand) => (
        <Menu.Item key={brand.brandId}>{brand.brandName}</Menu.Item>
      ))}
      {/* <Menu.Item key="Men">Men</Menu.Item> */}
      {/* <SubMenu title="Women" key="women">
                <Menu.Item key="thisisververthat">thisisververthat</Menu.Item>
                <Menu.Item key="unaffected">unaffected</Menu.Item>
                <Menu.Item key="recto man">recto man</Menu.Item>
                <Menu.Item key="lab101">lab101</Menu.Item>
            </SubMenu> */}
      {/* <Menu.Item key="Women">Women</Menu.Item>
            <Menu.Item key='Acc. "&amp;" Bag'>Acc. "&amp;" Bag</Menu.Item> */}
    </Menu>
  );

  const menuCategory = (
    <Menu onClick={handleMenuClick} expandIcon={<div />}>
      {categoryList?.map((category) => (
        <SubMenu title={category.categoryName}>
          {category.brands?.map((brand) => (
            <Menu.Item key={brand.brandId}>{brand.brandName}</Menu.Item>
          ))}
        </SubMenu>
      ))}
      {/* <Menu.Item key="Men">Men</Menu.Item> */}
      {/* <SubMenu title="Women" key="women">
                <Menu.Item key="thisisververthat">thisisververthat</Menu.Item>
                <Menu.Item key="unaffected">unaffected</Menu.Item>
                <Menu.Item key="recto man">recto man</Menu.Item>
                <Menu.Item key="lab101">lab101</Menu.Item>
            </SubMenu> */}
      {/* <Menu.Item key="Women">Women</Menu.Item>
            <Menu.Item key='Acc. "&amp;" Bag'>Acc. "&amp;" Bag</Menu.Item> */}
    </Menu>
  );

  useEffect(async () => {
    // //console.log("Brand Id useEffect : ", brandId);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_API}/api/brand/getBrandInfo`, {
        brandId: brandId,
      })
      .then((res) => {
        //console.log("res brand====", res.data.data);
        setBrandDetails(res.data.data);
        const mainSeason = res.data.data?.seasons.find((season) => season.main === true);
        if (mainSeason) {
          //console.log("MAIN SEASON -======-", mainSeason);
          setSeasonDetails(mainSeason);
        } else {
          //console.log("No main season set");
          setSeasonDetails(res.data.data?.seasons[0]);
        }
      });
  }, [brandId]);

  const getModelCut = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/model-cut/getActivatedModelCut`, {
        params: { id: seasonDetails?._id },
      })
      .then((res) => {
        //console.log("resposne model cut", res);
        let list = res.data.data;
        let colNum = 5;
        if (window.innerWidth > 545 && list.length > 0) {
          let length = res.data.data.length;
          let remainder = length % colNum;
          let add = colNum - remainder;
          if (add < 5)
            for (let i = 0; i < add; i++) {
              // list.push(null);
              list.push({
                images: ["https://via.placeholder.com/150/ffffff/ffffff"],
              });
            }
        }
        setModelDetails(list);
        //console.log("model list, ", list);
      })
      .catch((e) => {
        //console.log("???", e);
      });
    //console.log("???");
  };

  const getProductCut = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/product/getActivatedProducts`, {
        params: { id: seasonDetails?._id },
      })
      .then((res) => {
        //console.log("resposne product cut", res);
        let list = res.data.data;
        let colNum = 4;
        if (window.innerWidth > 545 && list.length > 0) {
          let length = res.data.data.length;
          let remainder = length % colNum;
          let add = colNum - remainder;
          for (let i = 0; i < add; i++) {
            list.push(null);
          }
        }
        setProductDetails(list);
        //console.log("product list, ", list);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const goDetail = (brandId, modelNum, index) => {
    setDetailIndex(index);
    history.push(`showroom/modelCutDetails?brandId=${brandId}&number=${modelNum}`);
  };

  const goDetail2 = (brandId, modelNum, index) => {
    setDetailIndex(index);
    history.replace(`modelCutDetails?brandId=${brandId}&number=${modelNum}`);
  };

  useEffect(() => {
    getModelCut();
    getProductCut();
    //console.log("seasonDetails", seasonDetails?.mainBannerImages);
  }, [seasonDetails]);

  useEffect(async () => {
    TokenVerification().then((result) => {
      //console.log("TokenVerification : ", result);
      if (!result) history.replace("/login");
    });

    await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getAllBrands`).then((res) => {
      //console.log("RESPONSE : ", res);
      setBrands(res.data.data);
    });

    await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getCategories`).then((res) => {
      //console.log("categories--------", res.data.data);
      setCategoryList(res.data.data);
    });
  }, []);

  function handleMenuClick(e) {
    //console.log("asdf");
    setBrandId(e.key);
    history.push(`/showroom?brandId=${e.key}`);
    scrollToTop();
    // window.location.reload();
  }

  function handleMenuClickFooter(e) {
    setBrandId(e);
    history.push(`/showroom?brandId=${e}`);
    scrollToTop();
  }

  useEffect(() => {
    //console.log("modalde", modelDetails);
    setModelResult(modelDetails?.find((model) => model?.number == parseInt(queries.number)));
  }, [queries.number, modelDetails]);

  useEffect(() => {
    makeLoop();
  }, []);

  const makeLoop = () => {
    //console.log("-----wrapperRef-----", wrapperRef);
    const document = wrapperRef.current;
    //console.log("---document----", document);
    gsap.set(document?.querySelectorAll(".ModelName"), {
      x: (i) => {
        return i * 325;
      },
    });

    gsap.to(document?.querySelectorAll(".ModelName"), {
      duration: 15,
      ease: "none",
      x: `+=${325 * 10}`,
      modifiers: {
        x: gsap.utils.unitize((x) => parseFloat(x) % (325 * 10)),
      },
      repeat: -1,
    });
  };

  // //console.log("Model result-------------------", modelResult);

  // const toggleVisible = () => {
  //     const scrolled = document.documentElement.scrollTop;
  //     if (scrolled > 100) {
  //         setVisible(true);
  //     } else if (scrolled <= 100) {
  //         setVisible(false);
  //     }
  // };

  const logout = () => {
    Logout();
    history.push("/");
  };

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = (e) => {
    setIsMobile(window.innerWidth <= 1024);
    makeLoop();
    setIsOne(window.innerWidth <= 545);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getModelCut();
    getProductCut();
    //console.log("one column changed");
  }, [isOne]);

  const options = {
    root: null,
    // rootMargin: isMobile ? "-150px 0px 0px 0px" : "0px",
    threshold: 0.3,
  };
  const containerRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    console.log(
      "Wall Two entry: ",
      entry.isIntersecting
      // brandDetails.brandName
    );
    setFooterShow(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, options]);

  return (
    <div>
      <div className="Header">
        <div className="FirstRow">
          <div className="Logo">
            <img
              src="/Images/Showroom/ideal_logo.svg"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            ></img>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="BrandsFilter">
              <Dropdown overlay={menuCategory}>
                <Button className="ButtonDD">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    SHOWROOM BRANDS
                    <i style={{ marginBottom: 6 }} class="fas fa-sort-down"></i>
                  </div>
                </Button>
              </Dropdown>
            </div>
            <div className="RightDiv">
              <a className="LoginText" onClick={logout}>
                Logout
              </a>
              <span>|</span>
              {/* LOGOUT USER AND GIVE PATH TO DIGITAL SHOWROOM PAGE */}
              <a className="AppointmentText" href="/Calendar">
                Appointment
              </a>
            </div>
          </div>
        </div>
        <div className="Nevigation">
          <a className="Routes" href="/digitalshowroom">
            Home
          </a>

          {brandDetails && (
            <>
              <i class="fas fa-angle-right"></i>
              <a
                className="Routes"
                onClick={() => {
                  //console.log("sadfasdf");

                  history.push(`/Showroom?brandId=${brandDetails._id}`);
                }}
                // href={`/Showroom?brandId=${brandDetails._id}`}
              >
                {brandDetails.brandName}
              </a>
              {queries.number && (
                <>
                  <i class="fas fa-angle-right"></i>
                  <a className="Routes">LOOK#{queries.number}</a>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="ThirdRow">
        <a className="Routes">SEASONS</a>
        <i class="fas fa-angle-right"></i>
        {brandDetails?.seasons.map((season, index) => (
          <>
            {" "}
            <a
              className="Routes"
              onClick={() => {
                setSeasonDetails(season);
                history.push(`/Showroom?brandId=${brandDetails._id}`);
              }}
            >
              {season.seasonName}
            </a>
            {brandDetails?.seasons[index + 1] && <span>|</span>}
          </>
        ))}
      </div>
      {window.location.pathname == "/showroom/modelCutDetails" ? (
        <div>
          <ModelCutDetails
            footerShow={footerShow}
            details={modelResult && modelResult}
            onPrev={() => {
              //console.log("onClick onPrev");
              if (modelDetails[detailIndex - 1])
                goDetail2(brandDetails?._id, modelDetails[detailIndex - 1].number, detailIndex - 1);
            }}
            onNext={() => {
              //console.log("onClick onNext");
              if (modelDetails[detailIndex + 1])
                goDetail2(brandDetails?._id, modelDetails[detailIndex + 1].number, detailIndex + 1);
            }}
            productDetails={productDetails}
            brandDetails={brandDetails}
          />
        </div>
      ) : (
        <>
          {seasonDetails?.mainBannerImages?.substring(
            seasonDetails?.mainBannerImages?.lastIndexOf(".") + 1
          ) == "mp4" ? (
            <div className="VideoContainer">
              <video
                className="showroomMainImg"
                playsinline="playsinline"
                width="100%"
                alt="brandInfo Image"
                muted
                autoPlay
                loop
              >
                <source src={seasonDetails?.mainBannerImages} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <div className="BrandText">{brandDetails?.brandName}</div> */}
            </div>
          ) : (
            <div className="ImageContainer">
              <img
                className="showroomMainImg"
                src={seasonDetails?.mainBannerImages}
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  objectFit: "cover",
                }}
              />
              {/* <div className="BrandText">{brandDetails?.brandName}</div> */}
            </div>
          )}
          <div className="ModelCutList">
            <div className="wrapper" ref={wrapperRef}>
              <div className="ModelNames">
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
                <div className="ModelName">
                  {" "}
                  {brandDetails?.brandName}/{seasonDetails?.seasonName}
                </div>
              </div>
            </div>
            <div className="productModelImages">
              <ModelCut
                goDetail={goDetail}
                modelDetails={modelDetails}
                brandDetails={brandDetails}
              />
            </div>
            <div className="productImages">
              <ProductCut productDetails={productDetails} brandDetails={brandDetails} />
            </div>
          </div>
        </>
      )}
      <div className="Footer" ref={containerRef}>
        <Footer
          handleMenuClickFooter={handleMenuClickFooter}
          seasonDetails={seasonDetails}
          categoryList={categoryList}
        />
      </div>

      <button class="ScrollToTopBtn" onClick={scrollToTop}>
        <i class="fas fa-angle-up"></i>
      </button>
    </div>
  );
}

export default Showroom;
