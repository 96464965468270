import React, { useState } from "react";
import { useHistory } from "react-router";
import "./Backbutton.scss";
import { Animated } from "react-animated-css";

function BottomButtons(props) {
    let history = useHistory();

    return (
        <div>
            <div className="calendarButton">
                <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    isVisible={props.show}
                >
                    <button
                        className="calendar_origin"
                        onClick={() => history.push("/calendar")}
                    >
                        CALENDAR
                    </button>
                    <button
                        className="calendar_effect"
                        onClick={() => history.push("/calendar")}
                    >
                        CALENDAR
                    </button>
                </Animated>
            </div>
            <div className="digitalButton">
                <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    isVisible={props.show}
                >
                    <button
                        className="digital_origin"
                        onClick={() => history.push("/digitalshowroom")}
                    >
                        DIGITAL SHOWROOM
                    </button>
                    <button
                        className="digital_effect"
                        onClick={() => history.push("/digitalshowroom")}
                    >
                        DIGITAL SHOWROOM
                    </button>
                </Animated>
            </div>
        </div>
    );
}

export default BottomButtons;
