import React, { Component } from "react";
import Logo from "../button/Logo";
import "./about.scss";
import About_footer_links from "./About_footer_links";
import Footer from "./Footer";
import Backbutton from "../button/Backbutton";
import MARKETINGIMG from "./images/MARKETING_PR_EVENT.jpg";
import { isMobile } from "react-device-detect";

export class Marketing extends Component {
  render() {
    return (
      <>
        <Logo />
        <Backbutton isDsPage />
        <div className="about_container first_child">
          {/* <div className="about_image">
            <img src="/Images/About_pics/Rectangle 571.jpg" />
          </div> */}
          {isMobile && (
            <div className="about_image">
              <img src={MARKETINGIMG} />
            </div>
          )}
          <div>
            <div className="about_textContainer">
              <div className="about_title">MARKETING / PR / EVENT</div>
              <div className="about_text">
                In order to raise the brand’s awareness and increase the influx of customers, IDEAL
                manages to conduct marketing with major retailers and seeding influencers &
                celebrities.
                <br />
                <br />
                From fashion shows and after-party to collaboration projects between large companies
                and major retailers, we have been planning and running a number of events that
                require global fashion expertise and experience.
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="about_image">
              <img src={MARKETINGIMG} />
            </div>
          )}
        </div>
        {/* <div className="about_container">
          <div>
            <div className="about_textContainer">
              <div className="about_title"></div>
              <div className="about_text">
                From fashion shows and after-party to collaboration projects
                between large companies and major retailers, we have been
                planning and running a number of events that require global
                fashion expertise and experience.
              </div>
            </div>
          </div>
          <div className="about_image">
            <img src="/Images/About_pics/Rectangle 572.png" />
          </div>
        </div> */}
        <About_footer_links />
        <Footer />
      </>
    );
  }
}

export default Marketing;
