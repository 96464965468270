import React from "react";

class useOnScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isIntersecting: false,
    };
  }

  componentDidMount() {
    console.log("componentdidmount : ", this.props);
    const observer = new IntersectionObserver(([entry]) =>
      this.setState({ isIntersecting: entry.isIntersecting })
    );
    observer.observe(this.props);
  }

  render() {
    return this.state.isIntersecting;
  }
}

export default useOnScreen;
