import React, { Component } from "react";
import Logo from "../button/Logo";
import "./about.scss";
import About_footer_links from "./About_footer_links";
import Footer from "./Footer";
import Backbutton from "../button/Backbutton";
import FULFILLIMG from "./images/FULFILLMENT_SERVICE.jpg";
import { isMobile } from "react-device-detect";

export class Fulfillment extends Component {
  render() {
    return (
      <>
        <Logo />
        <Backbutton isDsPage />
        <div className="about_container first_child">
          {isMobile && (
            <div className="about_image">
              <img src={FULFILLIMG} />
            </div>
          )}
          <div>
            <div className="about_textContainer">
              <div className="about_title">FULFILLMENT SERVICE</div>
              <div className="about_text">
                IDEAL provides fulfillment service for the convenience of the brand. It is a service
                that assists from packaging to shipping according to the various shipping
                instructions required by global retailers for brands that are difficult to allocate
                additional personnel for overseas delivery or prepare a warehouse space.
                <br />
                <br />
                Inquires:{" "}
                <a href="mailto:logistic@idealppl.com" target="_blank">
                  logistic@idealppl.com
                </a>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="about_image">
              <img src={FULFILLIMG} />
            </div>
          )}
        </div>
        {/* <div className="about_container">
          <div className="about_image">
            <img src="/Images/About_pics/Rectangle 572.png" />
          </div>
          <div>
            <div className="about_title"></div>
            <div className="about_text"></div>
          </div>
        </div> */}
        <About_footer_links />
        <Footer />
      </>
    );
  }
}

export default Fulfillment;
