import React, { useState, useRef, useEffect } from "react";
import { Carousel, Row, Col } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ProductCut from "./ProductCut";
import { isMobile } from "react-device-detect";
import { Animated } from "react-animated-css";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <p className="arrows">
        <RightCircleOutlined />
      </p>
    </div>
  );
};
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <p className="arrows">
        <LeftCircleOutlined />
      </p>
    </div>
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const CarouselArrows = ({ imageArray }) => {
  return (
    <>
      <Carousel
        autoplay
        arrows
        {...settings}
        style={{
          width: "80%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundSize: "cover",
        }}
      >
        {imageArray?.map((image) => {
          return (
            <div className="ModelImageOfCarousal">
              <img
                src={image}
                style={{ background: "#E5E5E5", height: "80vh" }}
              />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

function ModelCutDetails({
  details,
  onPrev,
  onNext,
  productDetails,
  brandDetails,
  footerShow,
}) {
  //console.log("MODEL RESULT details: ", details);
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [state, setState] = useState(false);
  const containerRef = useRef(null);

  const scrollToTop = () => {
    //console.log("ㅇㅇㅇ");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    //console.log("detail index: ", index);
  }, [index]);

  return (
    <div>
      <div
        ref={containerRef}
        className="ModelDetailsPage"
        style={{ paddingBottom: 99 }}
      >
        {/* <div className="ModelImages">
        <CarouselArrows imageArray={details?.images} />
      </div> */}
        <div className="ModelImages">
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={!footerShow}
          >
            <div className="arrows">
              <p className="arrow left">
                <LeftCircleOutlined
                  onClick={() => {
                    onPrev();
                    scrollToTop();
                  }}
                />
              </p>

              <p className="arrow right">
                <RightCircleOutlined
                  onClick={() => {
                    onNext();
                    scrollToTop();
                  }}
                />
              </p>
            </div>
          </Animated>

          {details?.images.map((model, i) => {
            return (
              <img
                className="img unselectable"
                src={model}
                // style={{ cursor: "pointer" }}
                // onClick={() => {
                //   //console.log("click image: ", i);
                //   setState(true);
                //   setIndex(i);
                //   setImages(details?.images);
                // }}
              />
            );
          })}
        </div>

        {/* <div className="ProductImages">
        {details?.products.map((product, i) => {
          return (
            <div className="ProductImage">
              <img
                className="img"
                src={product.images[0]}
                style={{
                  width: product.images[1] ? "50%" : "100%",
                }}
              />
              {product.images[1] && (
                <img className="img" src={product.images[1]} />
              )}
            </div>
          );
        })}

        {state && (
          <Lightbox
            mainSrc={images[index]}
            prevSrc={images[(index + images.length - 1) % images.length]}
            nextSrc={images[(index + 1) % images.length]}
            onMovePrevRequest={() => {
              setIndex((index + images.length - 1) % images.length);
            }}
            onMoveNextRequest={() => {
              setIndex((index + 1) % images.length);
            }}
            onCloseRequest={() => {
              setState(false);
              setImages([]);
            }}
          />
        )}
      </div> */}
      </div>
      <div className="ModelCutList" style={{ width: "100%" }}>
        <div
          className="productImages"
          style={{
            // gridTemplateColumns: "1fr 1fr 1fr 1fr",
            borderTop: "2px solid #000",
          }}
        >
          <ProductCut
            productDetails={productDetails}
            brandDetails={brandDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default ModelCutDetails;
