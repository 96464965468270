import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

function ModelCut({ modelDetails, brandDetails, goDetail }) {
  //   console.log("modelcut : ", modelDetails);

  const history = useHistory();
  return (
    <>
      {modelDetails?.map((data, index) => {
        return (
          <div
            style={{
              overflow: "hidden",
              borderTop: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <a
              onClick={() => {
                if (data?.images[0] != "https://via.placeholder.com/150/ffffff/ffffff")
                  goDetail(brandDetails?._id, data.number, index);
              }}
            >
              <img
                className={
                  data?.images[0] != "https://via.placeholder.com/150/ffffff/ffffff" &&
                  "productModelImages__hover"
                }
                src={data?.images[0]}
              ></img>
            </a>
          </div>
        );
      })}
    </>
  );
}

export default ModelCut;
