import {
  Parallax as SpringParallax,
  ParallaxLayer,
} from "@react-spring/parallax";
import BrandMainBanner from "./BrandMainBanner/BrandMainBanner";
import MainBannerOne from "./MainBanner/MainBannerOne";
import "./MainBanner/MainBannerOne.scss";
import { useIdleTimer } from "react-idle-timer";
import TopButtons from "../button/TopButtons";
import BottomButtons from "../button/BottomButtons";
import Mainlogos from "./MainBanner/MainLogos";
import { AnimatedBg, Transition } from "scroll-background";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import WallOne from "./BrandMainBanner/WallOne";
import WallTwo from "./BrandMainBanner/WallTwo";
import WallThree from "./BrandMainBanner/WallThree";

function MainBanner(props) {
  const [red, green, blue] = [69, 111, 225];
  const [move, setMove] = useState(true);
  const [wallOne, setWallOne] = useState([]);
  const [wallTwo, setWallTwo] = useState([]);
  const [wallThree, setWallThree] = useState([]);
  const [load, setLoad] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [selectBrand, setSelectBrand] = useState(
    localStorage.getItem("visitBanner")
  );
  const [datas, setDatas] = useState([]);

  // const [colorSet, setColorSet] = [[], [], []];
  const [y, setY] = useState(window.scrollY);
  // 143, 168, 187
  // const handleNavigation = (e) => {
  //   //console.log(window.scrollY, window.innerHeight);
  //   const y = 1 + (window.scrollY || window.pageYOffset) / 1000;
  //   const [r, g, b] = [255 / y, 255 / y, 255 / y].map(Math.round);
  //   //console.log(y, [r, g, b]);
  //   setBackgroundColor(`rgb(${r}, ${g}, ${b})`);
  //   // section1.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", (e) => handleNavigation(e));

  //   return () => {
  //     // return a cleanup function to unregister our function since its gonna run multiple times
  //     window.removeEventListener("scroll", (e) => handleNavigation(e));
  //   };
  // }, []);

  const handleOnIdle = (event) => {
    setMove(false);
    // ------- when user is idle
    // //console.log("home user is idle", event);
    // //console.log("home last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    // ------- when user is active
    // //console.log("home user is active", event);
    // //console.log("home time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    setMove(true);
    // ------- when user did something
    // //console.log("home user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 2500,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    // debounce: 500,
  });

  const setBgColor = (brand) => {
    // //console.log("brand", brand);
    // //console.log("asdf", datas.find((e) => e.brandId == brand)?.brandName);
    return datas.find((e) => e.brandId == brand)?.brandColor ?? "#ffffff";
  };

  // useEffect(() => {
  //   setBgWhite();
  // }, [window.scrollY]);

  const getBrandBannerImages2 = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/brand/getBrandBannerImages`
      )
      .then((res) => {
        //console.log("res", res.data.data);
        setDatas(
          res.data.data.filter((value) => value.brandBannerImages?.length > 0)
        );
      })
      .catch((err) => {
        //console.log("error : ", err);
      });
  };

  useEffect(() => {
    getBrandBannerImages2();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 1000);
  }, []);

  useEffect(() => {
    //console.log("move", move);
  }, [move]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setBackgroundColor(setBgColor(selectBrand));
  }, [selectBrand]);

  return (
    <div
      // style={{ backgroundColor: backgroundColor }}
      className="transBackground"
      style={{ backgroundColor: `${setBgColor(selectBrand)}` }}
    >
      {/* <h1>&nbsp;under construction</h1> */}
      <Mainlogos move={move} />
      <MainBannerOne move={move} />
      <BrandMainBanner
        load={load}
        setSelectBrand={setSelectBrand}
        selectBrand={selectBrand}
        datas={datas}
        setDatas={setDatas}
      />
    </div>
  );
}
export default MainBanner;
