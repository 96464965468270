import React from "react";
import { useHistory } from "react-router";
import "./Logo.scss";
import { Animated } from "react-animated-css";

function Logo(props) {
  let history = useHistory();
  return (
    <div className="ideal-logo">
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={props.show}
      >
        <div className="origin">
          <img
            onClick={() => history.push("/")}
            src="/Images/Logo/ideal_logo.svg"
          />
        </div>
        <div className="effect">
          <img
            onClick={() => history.push("/")}
            src="/Images/Logo/ideal_logo.svg"
          />
        </div>
      </Animated>
    </div>
  );
}

export default Logo;
