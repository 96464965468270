import React, { Component, createRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./MainBannerOne.scss";
import Parallax from "parallax-js";
import axios from "axios";
import moment from "moment-timezone";
import useOnScreen from "./useOnScreen";
import { Animated } from "react-animated-css";
import { TweenMax } from "gsap/gsap-core";

gsap.registerPlugin(ScrollTrigger);

const randMin = 360;
const randMax = 361;

let parallaxInstances = [];

class MovingGalleryV2 extends Component {
  constructor(props) {
    super(props);
    this.refContainer = React.createRef();
    this._movingGalleryRef = React.createRef();
    this._movingGalleryWrapperRef = React.createRef();
    this._headline = React.createRef();
    this.state = {
      move: true,
      width: 0,
      height: 0,
      sliderWrapperMultiplier: 1,
      // Array of booleans to check if all images finished loading
      dynamicRefs: this._assignDynamicRefs(
        this.props.movingGalleryProps.length
      ),
      dynamicRefImgs: this._assignDynamicRefs(
        this.props.movingGalleryProps.length
      ),
      toRender: [],
      loading: Array.from(
        { length: this.props.movingGalleryProps.length },
        (i) => (i = false)
      ),
      gsapTweens: Array.from(
        { length: this.props.movingGalleryProps.length },
        (i) => (i = null)
      ),
      time_kst: null,
      time_bst: null,
      time_edt: null,
      time_cst: null,
      options: {
        root: null,
        // rootMargin: "100px",
        threshold: 0.6,
      },
      visible: false,
      isAnimation: false,
      width: 0,
      height: 0,
      movingGalleryProps: this.props.movingGalleryProps,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.idleTimer = null;
  }

  componentDidMount() {}

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
    this.state.gsapTweens.map((e) => {
      if (e) e.kill();
    });
    parallaxInstances.map((item, i) => {
      // console.log("para", parallaxInstances[i]);

      if (parallaxInstances[i]) {
        // console.log("destroy!!!!");
        parallaxInstances[i].destroy();
        parallaxInstances[i] = null;
      }
    });
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const visible2 =
      document.documentElement.clientHeight * 0.6 < winScroll ? false : true;

    this.setState({
      visible2,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ move: nextProps.move });
    // this._initGallery();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);

    this.setState({ toRender: this._setItemsForRender() });

    if (this.state.loading.every(Boolean)) this._initGallery();

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    setTimeout(() => {
      this.doParallax();
      const observer = new IntersectionObserver(
        this.callbackFunction,
        this.state.options
      );
      if (this._movingGalleryRef.current) {
        observer.observe(this._movingGalleryRef.current);
      }

      return () => {
        if (this._movingGalleryRef.current) {
          observer.unobserve(this._movingGalleryRef.current);
        }
      };
    }, 500);

    setInterval(() => {
      this.fetchTime();
    }, 1000);

    // setInterval(() => {
    //   // if (!this.state.move)
    //   this.doParallax();
    // }, 8000);
  }

  doParallax() {
    var scene = document.getElementsByClassName("slider");
    parallaxInstances = Array.from({ length: scene.length }, (i) => (i = null));
    if (scene) {
      console.log("scene", scene);
      scene = [...scene];
      scene.map((t, index) => {
        parallaxInstances[index] = new Parallax(t, {
          relativeInput: true,
        });
        parallaxInstances[index].friction(0.2, 0.2);
      });
    }
  }

  fetchTime() {
    axios
      .get("https://www.worldtimeapi.org/api/timezone/Etc/UTC")
      .then((res) => {
        const time_utc = res.data.datetime;
        this.setState({
          ...this.state,
          time_kst: moment.utc(time_utc).tz("Asia/Seoul").format("HH:mm"),
          time_bst: moment.utc(time_utc).tz("Europe/London").format("HH:mm"),
          time_edt: moment.utc(time_utc).tz("America/New_York").format("HH:mm"),
          time_cst: moment.utc(time_utc).tz("America/Chicago").format("HH:mm"),
        });
      })
      .catch((err) => {
        console.log("error on fetching time : ", err);
      });
  }

  callbackFunction = (entries) => {
    const [entry] = entries;
    this.setState({ ...this.state, visible: entry.isIntersecting });
    return entry.isIntersecting;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("prevprops", prevProps);
    if (this.state.loading.every(Boolean)) {
      // console.log("loading", this.state.loading);
      if (!this.state.isAnimation) this._initGallery();
      if (prevProps.move != this.state.move) {
        this._initGallery();
      }
      // this._initGallery();
    }
    // console.log("!!");
  }

  _initGallery = () => {
    const imgArrConst = this.state.dynamicRefs;
    const imgConst = this.state.dynamicRefImgs;
    let imagesPosition = 0;
    let totalW = 0;
    let imgWidths = {};
    let additionalX = { val: 0 };
    let additionalXAnim = null;
    let offset = 0;

    let maxW = 0;

    // if (!this.state.isAnimation) {
    if (true) {
      imgArrConst.forEach((item, index) => {
        // console.log(item);
        gsap.set(item.current, {
          left: 0,
        });
      });

      imgArrConst.map((img, i) => {
        // console.log("img", img);
        // console.log(" parallaxInstances[i]");
        if (img.current) {
          console.log(
            "img",
            img.current.offsetWidth,
            imgConst[i].current.offsetLeft
          );
          const translateValue = imgConst[i].current.style.webkitTransform
            .replace("translate3d", "")
            .replace("(", "")
            .replace(")", "")
            .replace("px", "")
            .split(",");
          // let imgWidth = img.current?.getBoundingClientRect().width;
          // let imgWidth = img.current.offsetWidth;
          let imgWidth = imgConst[i].current.offsetWidth;
          //initial left position depending on previous photos
          let leftPosition = imagesPosition;
          //positioning the images before moving them
          gsap.set(img.current, {
            left:
              imgConst[i].current?.getBoundingClientRect().left +
              imgConst[i].current.offsetWidth,
            width: imgConst[i].current?.getBoundingClientRect().width,
            height: imgConst[i].current?.getBoundingClientRect().height,
          });
          imagesPosition += imgWidth;
          //calculating total imgs width
          // totalW += imgWidth;

          totalW =
            imgConst[i].current.offsetWidth +
            imgConst[i].current?.getBoundingClientRect().left;
          // totalW = imgConst[i].current.offsetWidth + img.current.offsetLeft;
          //create hash of width per index
          imgWidths[i] = totalW;
          imgWidths[i] =
            imgConst[i].current.offsetWidth +
            imgConst[i].current?.getBoundingClientRect().left;
        }
      });

      console.log("total slider length : ", totalW);

      let wrapperHeight = 0;
      this.state.dynamicRefs.forEach((item) => {
        if (
          item.current !== null &&
          item.current.getBoundingClientRect().height > wrapperHeight
        )
          wrapperHeight = item.current?.getBoundingClientRect().height;
      });
      if (this._movingGalleryWrapperRef.current !== null) {
        this._movingGalleryWrapperRef.current.style.height = `${wrapperHeight}px`;
      }
    }

    const totalWrapper = document.querySelector(".moving-gallery__wrapper");

    console.log("totalwrapper", totalWrapper.offsetWidth);

    let tweens = [...this.state.gsapTweens];

    imgArrConst.forEach((item, idxx) => {
      let index = item.current.getAttribute("data-index");
      let move = null;
      const clName = item.current.getAttribute("class");
      const imageSet = this.state.movingGalleryProps;
      imageSet.map((t) => {
        if (t.className === clName) move = t.move;
      });
      // console.log("para!!", parallaxInstances[0]);
      if (!this.state.move) {
        move = move / 14;
        TweenMax.to(tweens[index], 1, { timeScale: 6 });
        // tweens[index].duration(move);
        // parallaxInstances.map((item, i) => {
        //   console.log("para", parallaxInstances[i]);
        //   // if (parallaxInstances[i]) {
        //   //   // console.log("destroy!!!!");
        //   //   parallaxInstances[i].destroy();
        //   //   parallaxInstances[i] = null;
        //   // }
        // });
        // tweens[index] = gsap.to(item.current, {
        //   x: `-=${totalW}`,
        //   duration: move,
        //   repeat: -1,
        //   ease: "none",
        //   overwrite: true,
        //   modifiers: {
        //     x: (x, arr) => {
        //       const imgIndex = arr.getAttribute("data-index");
        //       // console.log("img width", imgIndex, imgWidths[imgIndex]);
        //       // let maxLeftTravel = -imgWidths[imgIndex];
        //       // console.log("parallaxInstances[i]", parallaxInstances[index]);
        //       let maxLeftTravel = -imgWidths[imgIndex];
        //       let rightPositioning = totalW + maxLeftTravel;
        //       var mod = gsap.utils.wrap(maxLeftTravel, rightPositioning);
        //       offset += additionalX.val;
        //       return `${mod(parseFloat(x) + offset)}px`;
        //     },
        //   },
        // });
      } else {
        if (!this.state.isAnimation) {
          tweens[index] = gsap.to(item.current, {
            x: `-=${totalW}`,
            duration: move,
            repeat: -1,
            ease: "none",
            modifiers: {
              x: (x, arr) => {
                const imgIndex = arr.getAttribute("data-index");
                // console.log("img width", imgIndex, imgWidths[imgIndex]);
                let maxLeftTravel = -imgWidths[imgIndex] - 500;
                let rightPositioning = totalW + maxLeftTravel;
                var mod = gsap.utils.wrap(maxLeftTravel, rightPositioning);
                offset += additionalX.val;
                return `${mod(parseFloat(x) + offset)}px`;
              },
            },
            overwrite: true,
          });
        } else {
          TweenMax.to(tweens[index], 1, { timeScale: 1 });
          // tweens[index] = gsap.to(item.current, {
          //   x: `-=${totalW}`,
          //   duration: move,
          //   repeat: -1,
          //   ease: "none",
          //   overwrite: true,
          //   modifiers: {
          //     x: (x, arr) => {
          //       const imgIndex = arr.getAttribute("data-index");
          //       // console.log("img width", imgIndex, imgWidths[imgIndex]);
          //       let maxLeftTravel = -imgWidths[imgIndex];
          //       let rightPositioning = totalW + maxLeftTravel;
          //       var mod = gsap.utils.wrap(maxLeftTravel, rightPositioning);
          //       offset += additionalX.val;
          //       return `${mod(parseFloat(x) + offset)}px`;
          //     },
          //   },
          // });
          // parallaxInstances.map((item) => {
          //   item.enable();
          // });
          // this.doParallax();
        }
      }
      // move = 200;

      // var tl = gsap.timeline({ repeat: -1 });
      // tl.to(item.current, {
      //   duration: move * widthMove * (imgWidths[index] / totalW),
      //   x: `-=${imgWidths[index]}`,
      // }); // 왼쪽으로
      // tl.to(item.current, { duration: move * heightMove, y: `-=${this.state.height}` }); //위로
      // tl.to(item.current, { duration: move * widthMove, x: totalW }); //오른쪽으로
      // tl.to(item.current, { duration: move * heightMove, y: `+=${this.state.height}` }); //아래로
      // tl.to(item.current, {
      //   duration: move * widthMove * ((totalW - imgWidths[index]) / totalW),
      //   x: `-=${totalW}`,
      // }); //왼쪽으로

      // gsap.fromTo(
      //   item.current,
      //   {
      //     x: 4000,
      //   },
      //   { x: -totalW - 1080, duration: 10, repeat: -1 }
      // );
    });
    if (this.state.isAnimation == false)
      this.setState({ ...this.state, isAnimation: true, gsapTweens: tweens });
  };

  _imageLoadedHandler(index) {
    let tempState = this.state.loading;
    tempState[index] = true;
    this.setState({ loading: tempState });
  }

  _setItemsForRender = () => {
    let toRenderAssign = [];
    const duplicateArr = (arr, times) =>
      Array(times)
        .fill([...arr])
        .reduce((a, b) => a.concat(b));

    const multipliedSlidesArray = duplicateArr(
      this.state.movingGalleryProps,
      this.state.sliderWrapperMultiplier
    );

    multipliedSlidesArray.forEach((item, index) => {
      toRenderAssign.push(
        <div
          className={`${item.className}`}
          data-index={index}
          ref={this.state.dynamicRefs[index]}
          data-relative-input="true"
        >
          {item.url?.substring(item.url?.lastIndexOf(".") + 1) == "mp4" ? (
            <video
              playsinline="playsinline"
              data-depth={item.depth}
              className={item.className}
              className="video"
              onLoad={() => this._imageLoadedHandler(index)}
              ref={this.state.dynamicRefImgs[index]}
            >
              <source src={item.url} type="video/mp4" />
            </video>
          ) : (
            <img
              data-depth={item.depth}
              className={item.className}
              className="photos"
              onLoad={() => this._imageLoadedHandler(index)}
              src={item.url}
              alt={item.url}
              ref={this.state.dynamicRefImgs[index]}
              style={{
                ...(multipliedSlidesArray.length - 1 == index && {
                  paddingRight: 100,
                }),
              }}
            />
          )}
        </div>
      );
    });

    return toRenderAssign;
  };

  _generateArrayUtil(size) {
    const generateArray = (n) => [...Array(n)].map((_, index) => index + 1);
    return generateArray(size);
  }

  _assignDynamicRefs = (size) => {
    const arr = this._generateArrayUtil(size);
    const refsArray = arr.map(() => createRef());
    const map2 = document.getElementsByClassName("slider");
    let map3 = [];

    for (var i = 0, len = map2.length; i < len; i++) {
      map3.push({ current: map2[i] });
    }
    // return map3;
    return refsArray;
  };

  moveOn = (e) => {};

  parallaxIt(e, ele, movement) {
    // var scene = document.getElementById("scene");
    if (this._movingGalleryRef.current.getBoundingClientRect()) {
      const relX =
        e.pageX - this._movingGalleryRef.current.getBoundingClientRect().left;
      const relY =
        e.pageY - this._movingGalleryRef.current.getBoundingClientRect().top;
      const yoX = this._movingGalleryRef.current.getBoundingClientRect();

      gsap.to(ele, {
        duration: 1,
        stagger: 0,
        x:
          ((relX -
            this._movingGalleryRef.current.getBoundingClientRect().width / 2) /
            this._movingGalleryRef.current.getBoundingClientRect().width) *
          movement,
        y:
          ((relY -
            this._movingGalleryRef.current.getBoundingClientRect().height / 2) /
            this._movingGalleryRef.current.getBoundingClientRect().height) *
          movement,
      });
      // );
    }
  }

  storageChanged(e) {
    console.log("toRender", this.state.loading);
  }

  _renderItems = () => this.state.toRender.map((item) => item);

  render() {
    return (
      <div>
        <div
          // onMouseMove={(e) => this.moveOn(e)}
          ref={this._movingGalleryRef}
          style={{ minHeight: window.innerHeight }}
          className="moving-gallery hidden"
        >
          <>
            {/* <div className="ideal-logo">
              <img src="/Images/Logo/ideal_logo.svg" />
            </div> */}
            <div className="world-clock">
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={this.state.move}
              >
                <img src="/Images/Clock/Clock.svg" />
              </Animated>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={this.state.move}
              >
                <div className="clock_content">
                  <p>
                    <span>{this.state.time_kst}</span> <span>kst</span>
                  </p>
                  <p>
                    <span>{this.state.time_bst}</span> <span>bst</span>
                  </p>
                  <p>
                    <span>{this.state.time_edt}</span> <span>edt</span>
                  </p>
                  <p>
                    <span>{this.state.time_cst}</span> <span>cst</span>
                  </p>
                </div>
              </Animated>
            </div>

            <div>
              {/* <TopButtons /> */}
              <h2
                ref={this._headline}
                style={{ zIndex: 2, top: "31vh" }}
                className="ideal_showroom__main cursor"
              >
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={this.state.move && this.state.visible2}
                >
                  {this.props.headlight}
                </Animated>
              </h2>
            </div>
            {/* <BottomButtons /> */}
          </>
          <div
            ref={this._movingGalleryWrapperRef}
            className="moving-gallery__wrapper yo"
            // style={{ background: "transparent" }}
            // data-relative-input="true"
            // id="scene"
          >
            {this._renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

class MainBannerOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      k: null,
      move: true,
      movingGalleryProps1: [],
      palletOne: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ move: nextProps.move });
  }

  componentWillMount() {
    this.fetchPallet().then((x) => {
      let tempOne = [
        {
          url: null,
          className: "banOne1 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne2 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne3 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne4 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne5 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne6 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne7 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne8 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne9 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne10 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne11 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne12 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne13 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne14 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne15 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne16 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne17 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne18 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: Math.random() * (randMax - randMin) + randMin,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: 400,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: 400,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: 400,
        },
        {
          url: null,
          className: "banOne19 slider",
          depth: Math.random() * (0.3 - 0.1) + 0.1,
          move: 400,
        },
      ];

      this.state.palletOne?.mainBannerImages.map((value, index) => {
        tempOne[index].url = this.state.palletOne?.mainBannerImages[index];
      });

      tempOne = tempOne.filter((item) => !!item.url);

      this.setState(
        {
          ...this.state,
          k: 1,
          movingGalleryProps1: tempOne,
        },
        () => {
          console.log("movingGalleryProps1", this.state.movingGalleryProps1);
        }
      );
    });
  }

  fetchPallet = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/banner/getBanner`)
      .then((res) => {
        console.log("response : ", res.data.data);
        this.setState({ palletOne: res.data.data });
      })
      .catch((er) => {
        console.log("error : ", er);
      });
  };

  render() {
    return (
      <div
        className={
          this.state.palletOne?.palletNo == 1
            ? "brandmainbanner1"
            : this.state.palletOne?.palletNo == 2
            ? "brandmainbanner2"
            : "brandmainbanner3"
        }
      >
        {this.state.movingGalleryProps1[0] && (
          <MovingGalleryV2
            move={this.state?.move}
            movingGalleryProps={this.state?.movingGalleryProps1}
            headlight={this.state.palletOne?.headlight}
            palletNo={this.state.palletOne?.palletNo}
          />
        )}
      </div>
    );
  }
}

export default MainBannerOne;
