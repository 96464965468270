import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import "./Backbutton.scss";

function Backbutton({
  isDsPage,
  noEffect,
  noAnimation = false,
  isCalendar = false,
  isIndex = false,
  customBackClick,
}) {
  let history = useHistory();

  return (
    <div
      className="backButton"
      style={{
        mixBlendMode: noEffect ? "normal" : "difference",
        background: isDsPage ? "black" : "white",
        color: isDsPage ? "white" : "black",
        transform: isCalendar && isMobile ? "rotate(0deg)" : "rotate(90deg)",
        zIndex: 1000,
      }}
    >
      <button
        // onClick={() => window.location.replace("/")}
        onClick={() => {
          if (customBackClick) {
            customBackClick();
          } else history.go(-1);
        }}
        className={!isIndex && `origin ${noAnimation && "noHover"}`}
      >
        CLOSE
      </button>
      {!isIndex && (
        <button
          // onClick={() => window.location.replace("/")}
          onClick={() => {
            if (customBackClick) {
              customBackClick();
            } else history.go(-1);
          }}
          className={`effect  ${noAnimation && "noHover"}`}
        >
          CLOSE
        </button>
      )}
    </div>
  );
}

export default Backbutton;
