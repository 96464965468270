import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import heading from "../../../assets/ideal-logo.svg";
import back from "../../../assets/back.svg";
import Logo from "../../button/Logo";

import config from "../../../config";
import TokenVerification from "../TokenVerification/TokenVerification";

const Login = ({ urlPath, loadLoggedInUser }) => {
  const history = useHistory();

  const [companyEmail, setCompanyEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(async () => {
    TokenVerification().then((result) => {
      console.log("login TokenVerification : ", result);
      if (result) history.go(-1);
    });
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    /* Please change prod ideal_api_url in config while production*/
    console.log(
      "process.env.REACT_APP_BACKEND_API",
      process.env.REACT_APP_BACKEND_API
    );
    fetch(`${config.ideal_api_url}/login`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyEmail,
        password,
      }),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          setError(
            "It seems your account has not been registered yet. Please sign up"
          );
        } else if (response.status === 401) {
          setError("Please check your email address or password again.");
        } else if (response.status === 200) {
          console.log("200뜬거임?", response.json);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.code == 500) {
          setError("Please check your email address or password again.");
          return false;
        }
        console.log("여기까지 온거임??", data);
        localStorage.setItem("authToken", data.authToken);
        loadLoggedInUser(data.company);
        urlPath === "/calendar"
          ? history.push("/calendar")
          : TokenVerification().then((result) => {
              console.log("result", result);
              if (result) {
                // history.go(-1);
                history.push("digitalshowroom");
              } else {
                history.push("/login-completed");
              }
            });
      })
      .catch((er) => {
        console.log("error : ", er);
      });
  };

  return (
    <>
      <Logo />
      <div className="login">
        <div className="login__container">
          <div className="login__title">
            <img src={heading} style={{ filter: "invert(1)", height: 30 }} />
          </div>
          <form onSubmit={(e) => handleLogin(e)} className="login__form">
            {error && (
              <div className="login__error">
                <p>{error}</p>
              </div>
            )}
            <div className="login__input-wrapper">
              <label htmlFor="email">Email</label>
              <input
                onChange={(e) => setCompanyEmail(e.target.value)}
                type="email"
                name="company-email"
                id="company-email"
                placeholder="Please enter your email"
              />
            </div>
            <div className="login__input-wrapper">
              <label htmlFor="password">Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                id="password"
                placeholder="Please enter your password"
              />
            </div>

            <button>Log In</button>
          </form>
          <Link className="login__signup" to="/signup">
            ACCESS REQUEST
          </Link>
          <div className="login__back" onClick={() => history.go(-2)}>
            <img src={back} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
