import React from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";

function Footer({ categoryList, seasonDetails, handleMenuClickFooter }) {
  console.log("FOOTER----", categoryList, seasonDetails);
  const history = useHistory();

  return (
    <div>
      <div className="FooterButton">
        <button
          onClick={() => {
            window.open(seasonDetails?.joorLink, "_blank");
          }}
        >
          <span>BRAND WEBSITE</span>
        </button>
        <button onClick={() => history.push("/calendar")}>
          <span>Appointment booking</span>
        </button>
        <button
          onClick={() =>
            seasonDetails?.excelLink &&
            saveAs(
              seasonDetails?.excelLink,
              seasonDetails?.excelLink.split("/")[
                seasonDetails?.excelLink.split("/").length - 1
              ]
            )
          }
        >
          <span>Download linesheet</span>
        </button>
        <button
          onClick={() =>
            seasonDetails?.lookBook &&
            saveAs(
              seasonDetails?.lookBook,
              seasonDetails?.lookBook.split("/")[
                seasonDetails?.lookBook.split("/").length - 1
              ]
            )
          }
        >
          <span> Download lookbook</span>
        </button>
      </div>

      <div className="FooterOptions">
        {categoryList?.map((categories) => (
          <div className="Block">
            <div className="BlockCategory">
              <p>+ {categories.categoryName}</p>
            </div>
            <ul className="BlockBrandNames ">
              {categories.brands.map((brand) => (
                <li
                  className="cursor"
                  onClick={() => handleMenuClickFooter(brand.brandId)}
                >
                  {brand.brandName}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Footer;
