import React, { useEffect, useRef, useState } from "react";
import "./Brandinfo2.scss";
import Parallax from "parallax-js";
import { useHistory, useLocation } from "react-router";
import Logo from "../button/Logo";
import Backbutton from "../button/Backbutton";
import axios from "axios";
import WallOne from "../Main/BrandMainBanner/WallOne";
import WallTwo from "../Main/BrandMainBanner/WallTwo";
import WallThree from "../Main/BrandMainBanner/WallThree";

import Lightbox from "react-image-lightbox";
import Close from "../../assets/close.svg";
import "react-image-lightbox/style.css";
import { isMobile } from "react-device-detect";
import Mainlogos from "../Main/MainBanner/MainLogos";
import { Animated } from "react-animated-css";

const DATA_DEPTH = [
  "0.2",
  "0.1",
  "0.3",
  "0.2",
  "0.1",
  "0.1",
  "0.2",
  "0.1",
  "0.3",
  "0.2",
  "0.3",
  "0.1",
];

const DATA_ID = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Brandinfo2 = ({ match }) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [position, setPosition] = useState({ top: null, left: null });
  const [initialSlide, setInitialSlide] = useState(0);
  const [brandId, setBrandId] = useState(null);
  const [brandBannerImages, setBrandBannerImages] = useState([]);
  const [nextBanner, setNextBanner] = useState([]);
  let sliders = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  let history = useHistory();
  const [images, setImages] = useState([]);
  const [state, setState] = useState(false);

  const [index, setIndex] = useState(0);
  const [nextBrand, setNextBrand] = useState("");
  const [curBrand, setCurBrand] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      const pic_container = document.getElementsByClassName("photos");
      for (let i = 0; i < pic_container.length; i++) {
        let parallaxInstance = new Parallax(pic_container[i], {
          relativeInput: true,
        });
        parallaxInstance.friction(0.2, 0.2);
      }
    }, 1000);
  }, [isOpen]);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  const options = {
    root: null,
    rootMargin: "100px",
    threshold: isMobile ? 0.2 : 0.05,
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (match.params?.brandId) {
      setBrandId(match.params.brandId);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}/api/brand/getBrandIntroductionImages`,
          { params: { id: match.params.brandId } }
        )
        .then((res) => {
          setBrandBannerImages(res.data.data[0]);
          setCurBrand(res.data.brand);
          getBrandBannerImages(res.data.nextBrand);
          setLoading(false);
        })
        .catch((er) => {
          //console.log("error : ", er);
          setLoading(false);
        });
    }
  }, [match.params?.brandId]);

  const getBrandBannerImages = (nextId) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/brand/getBrandIntroductionImages`,
        // { params: { id: match.params.nextId } }
        { params: { id: nextId } }
      )
      .then((res) => {
        //console.log(res.data.brand);
        setNextBanner(res.data.brand);
        setNextBrand(res.data.nextBrand);
      })
      .catch((err) => {
        //console.log("error : ", err);
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, options]);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const settings = {
    className: "main-slider",
    infinite: true,
    initialSlide: initialSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    swipeToSlide: true,
  };

  const next = () => {
    sliders.slickNext();
  };
  const previous = () => {
    sliders.slickPrev();
  };

  const handleMouseMove = (e) => {
    setPosition({ top: e.pageY - 50, left: e.pageX - 50 });
  };

  const setVisitBanner = (bannerId) => {
    localStorage.setItem("visitBanner", bannerId);
  };

  return (
    <div>
      <div
        className="Brandinfo2 transBackground"
        style={{
          background: isVisible
            ? location.state?.brandColor
            : nextBanner?.brandColor ?? location.state?.brandColor,
        }}
      >
        {/* <Mainlogos /> */}
        <div
          className="aboutButton"
          onClick={() => {
            if (location.state.isIndex) {
              history.go(-1);
            } else {
              window.location.replace("/");
            }
          }}
        >
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            // isVisible={props.show}
          >
            <button className="about_origin">CLOSE</button>
            <button className="about_effect">CLOSE</button>
          </Animated>
        </div>
        {/* <Backbutton
          isDsPage
          customBackClick={() => window.location.replace("/")}
        /> */}

        <Logo />
        {isVisible && (
          <h1 id="Brandinfo2_upper_text">{match.params?.brandName}</h1>
        )}

        <div
          ref={containerRef}
          className="container_photos"
          style={{ minHeight: "100vh" }}
        >
          {!loading &&
            brandBannerImages?.length > 0 &&
            brandBannerImages.map((item, index) => {
              return (
                <div
                  id={`Brandinfo2_pic_${DATA_ID[index]}`}
                  className="photos"
                  data-relative-input="true"
                >
                  {brandBannerImages[index]?.substring(
                    brandBannerImages[index]?.lastIndexOf(".") + 1
                  ) == "mp4" ? (
                    <video
                      playsinline="playsinline"
                      onClick={(e) => {
                        setInitialSlide(index);
                        handleToggle(e);
                      }}
                      // width="100%"
                      alt="brandInfo Image"
                      data-depth={DATA_DEPTH[index]}
                      muted
                      autoPlay
                      loop
                      className={`brand-img-${DATA_ID[index]}`}
                    >
                      <source src={brandBannerImages[index]} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      onClick={(e) => {
                        setState(true);
                        setIndex(index);
                      }}
                      alt="brandInfo Image"
                      data-depth={DATA_DEPTH[index]}
                      src={brandBannerImages[index]}
                      // width="100%"
                      className={`brand-img-${DATA_ID[index]}`}
                    ></img>
                  )}
                </div>
              );
            })}
        </div>
        <div
          className="nextBanner"
          onClick={() => {
            //console.log("??");
            setVisitBanner(nextBanner._id);
            history.push({
              pathname: `/brandinfo${nextBanner.bannerType}/${nextBanner._id}/${nextBanner.brandName}`,
              state: {
                brandColor: nextBanner.brandColor,
              },
            });
            if (nextBanner.bannerType == 2) {
              window.location.reload();
            }
          }}
          style={{ marginTop: 480 }}
        >
          {nextBanner.bannerType == 1 && (
            <WallOne data={nextBanner} isBanner backVisible={isVisible} />
          )}
          {nextBanner.bannerType == 2 && (
            <WallTwo data={nextBanner} isBanner backVisible={isVisible} />
          )}
          {nextBanner.bannerType == 3 && (
            <WallThree data={nextBanner} isBanner backVisible={isVisible} />
          )}
        </div>
      </div>
      {state && (
        <Lightbox
          mainSrc={brandBannerImages[index]}
          prevSrc={
            brandBannerImages[
              (index + images.length - 1) % brandBannerImages.length
            ]
          }
          nextSrc={brandBannerImages[(index + 1) % brandBannerImages.length]}
          onMovePrevRequest={() => {
            setIndex(
              (index - 1 + brandBannerImages.length) % brandBannerImages.length
            );
          }}
          onMoveNextRequest={() => {
            setIndex((index + 1) % brandBannerImages.length);
          }}
          toolbarButtons={[
            <div className="closeButton">
              <img
                src={Close}
                onClick={() => {
                  setState(false);
                  setImages([]);
                }}
              />
            </div>,
          ]}
        />
      )}
    </div>
  );
};

export default Brandinfo2;
