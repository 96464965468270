import axios from "axios";
const TokenVerification = async () => {
    try {
        let result = await axios.get(
            `${process.env.REACT_APP_BACKEND_API}/verify`,
            {
                headers: {
                    "x-access-token": localStorage.getItem("authToken"),
                },
            }
        );
        if (result.status == 200) {
            console.log("TokenVerification : ", result);
            return true;
        }
    } catch (err) {
        console.log("TokenVerification error  : ", err);
        return false;
    }
};
export default TokenVerification;
