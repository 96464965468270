import React, { Component, useRef } from "react";
import { useHistory } from "react-router";
import "./about.scss";
import Backbutton from "../button/Backbutton";
import Logo from "../button/Logo";
import Footer from "./Footer";

const About_mainpage = () => {
  let history = useHistory();
  return (
    <>
      <Logo />
      <div className="About_mainpage">
        <div className="h1-text">IDEAL SHOWROOM FASHION AGENCY BASED IN SEOUL</div>
        <br />
        <div
          className="h2-text cursor"
          onClick={() => window.open("https://www.instagram.com/idealpeople_official/", "_blank")}
        >
          INSTAGRAM
        </div>
        <div
          className="h2-text cursor"
          style={{ marginTop: 0 }}
          onClick={() => {
            window.open("mailto:GENERAL@IDEALPPL.COM", "_blank");
          }}
        >
          GENERAL@IDEALPPL.COM
        </div>
        <br />
        <Backbutton isDsPage />
        <div className="contents">
          <div className="contents_links_container">
            <div id="contents_links" onClick={() => history.push("/about-main/about")}>
              ABOUT US
            </div>
            <div
              id="contents_links"
              onClick={() => history.push("/about-main/International_wholesale")}
            >
              INTERNATIONAL WHOLESALE
            </div>
            <div id="contents_links" onClick={() => history.push("/about-main/Marketing")}>
              MARKETING / PR /EVENT
            </div>
            <div id="contents_links" onClick={() => history.push("/about-main/Brand_Distribution")}>
              LICENSING & DISTRIBUTION
            </div>
            <div id="contents_links" onClick={() => history.push("/about-main/Fulfillment")}>
              FULFILLMENT
            </div>
          </div>
        </div>
      </div>
      <hr className="footer__hr" />
      <Footer />
    </>
  );
};

export default About_mainpage;
