import { useHistory } from "react-router-dom";

const About_footer_links = () => {
  const history = useHistory();
  return (
    <>
      <div className="about_us_link">
        <div>ABOUT US</div>
        <div className="about_us_link_box row">
          <div className="cursor" onClick={() => history.replace("/about-main/about")}>
            About us
          </div>
          <div
            className="cursor"
            onClick={() => history.replace("/about-main/International_wholesale")}
          >
            International wholesale
          </div>
          <div className="cursor" onClick={() => history.replace("/about-main/Marketing")}>
            Marketing / PR / event
          </div>
          <div className="cursor" onClick={() => history.replace("/about-main/Brand_Distribution")}>
            Licensing & Distribution
          </div>
          <div
            style={{ borderRight: 0 }}
            className="cursor"
            onClick={() => history.replace("/about-main/Fulfillment")}
          >
            Fulfilment Service
          </div>
        </div>
      </div>
    </>
  );
};

export default About_footer_links;
