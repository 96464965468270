import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Component/authentication/Login/Login";
import LoginCompleted from "./Component/authentication/Login Complete/LoginCompleted";
import Signup from "./Component/authentication/Signup/Signup";
import SignupCompleted from "./Component/authentication/Signup Complete/SignupCompleted";
import Calendar from "./Component/Calendar/Calendar";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import BrandList from "./Component/Index/BrandList";
import BrandListTemp from "./Component/Index/BrandListTemp";
import BrandListTemp2 from "./Component/Index/BrandListTemp2";

import { Redirect } from "react-router-dom";

import MainBanner from "./Component/Main/MainBanner";
import MainBannerTwo from "./Component/Main/MainBanner/MainBannerTwo";
import Brandinfo1 from "./Component/BrandInfo/Brandinfo1";
import About_mainpage from "./Component/about/About_mainpage";
import Brandinfo2 from "./Component/BrandInfo/Brandinfo2";
import Brandinfo3 from "./Component/BrandInfo/Brandinfo3";
import Showroom from "./Component/Showroom/Showroom";
import DigitalShowroom from "./Component/DigitalShowroom/DigitalShowroom";
import About from "./Component/about/About";
import International_wholesale from "./Component/about/International_wholesale";
import Marketing from "./Component/about/Marketing";
import Brand_Distribution from "./Component/about/Brand_Distribution";
import Fulfillment from "./Component/about/Fulfillment";

function App() {
  // Get url path for dynamic routing of login
  const [urlPath, setUrlPath] = useState("");
  const handleLoadUrlPath = (data) => {
    setUrlPath(data);
  };

  // Load logged in user
  const [loggedInUser, setLoggedInUser] = useState(null);
  const handleLoadLoggedInUser = (data) => {
    setLoggedInUser(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={MainBanner}></Route>
        <Route
          exact
          path="/login"
          component={() => (
            <Login
              urlPath={urlPath}
              loadLoggedInUser={handleLoadLoggedInUser}
            />
          )}
        ></Route>
        <Route exact path="/login-completed" component={LoginCompleted}></Route>
        <Route exact path="/signup" component={Signup}></Route>
        <Route
          exact
          path="/signup-completed"
          component={SignupCompleted}
        ></Route>
        <Route exact path="/new" component={MainBannerTwo}></Route>
        <Route
          exact
          path="/calendar"
          component={() => (
            <Calendar
              loadUrlPath={handleLoadUrlPath}
              loggedInUser={loggedInUser}
            />
          )}
        ></Route>
        <Route exact path="/index" component={BrandList}></Route>
        {/* <Route exact path="/index" component={BrandListTemp2}></Route> */}
        {/* <Route exact path="/index" component={BrandListTemp}></Route> */}
        <Route
          // exact
          path="/brandinfo1/:brandId/:brandName"
          component={Brandinfo1}
          // render={({ match }) => <Brandinfo1 match={match} />}
        ></Route>
        <Route
          exact
          path="/brandinfo2/:brandId/:brandName"
          component={Brandinfo2}
        ></Route>
        <Route
          exact
          path="/brandinfo3/:brandId/:brandName"
          component={Brandinfo3}
        ></Route>
        <Route path="/showroom" component={Showroom}></Route>
        <Route
          exact
          path="/digitalShowroom"
          component={DigitalShowroom}
        ></Route>
        <Route exact path="/about-main" component={About_mainpage}></Route>
        <Route exact path="/about-main/about" component={About}></Route>
        <Route
          exact
          path="/about-main/International_wholesale"
          component={International_wholesale}
        ></Route>
        <Route exact path="/about-main/Marketing" component={Marketing}></Route>
        <Route
          exact
          path="/about-main/Brand_Distribution"
          component={Brand_Distribution}
        ></Route>
        <Route
          exact
          path="/about-main/Fulfillment"
          component={Fulfillment}
        ></Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
