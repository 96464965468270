import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import TokenVerification from "../authentication/TokenVerification/TokenVerification";
import BrandList from "../Index/BrandList";
import Logo from "../button/Logo";
import "./DigitalShowroom.scss";

function DigitalShowroom() {
  let history = useHistory();

  useEffect(async () => {
    TokenVerification().then((result) => {
      //console.log("TokenVerification : ", result);
      if (!result) history.push("/login");
    });
  }, []);

  return (
    <div>
      <div className="digitalShowroom__title">
        <span>DIGITAL SHOWROOM</span>
      </div>
      <BrandList isDsPage={true} />
    </div>
  );
}

export default DigitalShowroom;
