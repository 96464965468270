import React, { useRef, useEffect, useState, useMemo } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Backbutton from "../button/Backbutton";
import "./BrandList.scss";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import { throttle } from "lodash";
import Logo from "../button/Logo";

function BrandList({ isDsPage }) {
  let history = useHistory();
  gsap.registerPlugin(MotionPathPlugin);
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);

  // const [brandDetails, setBrandDetails] = useState();
  const [brandImageList, setBrandImageList] = useState([]);
  // let bi = 0;
  const [categoryList, setCategoryList] = useState();
  const [circleSize, setCircleSize] = useState(600);

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getCategories`)
      .then((res) => {
        //console.log("categories--------", res.data.data);
        setCategoryList(res.data.data);
        let list = [];
        for (let i = 0; i < res.data.data.length; i++) {
          list = [...list, ...res.data.data[i].brands];
        }
        setBrandImageList(list);
        //console.log("CategoryList", res.data.data);
        //console.log("BrandImageList", list);
      });

    // await axios
    //   .get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getAllBrands`)
    //   .then((res) => {
    //     //console.log("RESPONSE : ", res);
    //     setBrandDetails([...res.data.data]);
    //   });

    // if (isMobile) setCircleSize(300);
    //console.log("isMobile: ", isMobile);
  }, []);

  const handleResize = (e) => {
    const element = section.current;
    const brands = gsap.utils.toArray(element?.querySelectorAll(".brands"));
    const num = brands.length;

    MotionPathPlugin.convertToPath(element?.querySelector("#path"));
    // brands.forEach((div, i) => {
    //   gsap.timeline().to(div, {
    //     motionPath: {
    //       path: element?.querySelector("#path"),
    //       align: element?.querySelector("#path"),
    //       alignOrigin: [0.5, 0.5],
    //       autoRotate: true,
    //       end: i * (1 / num),
    //     },
    //     duration: 0.5,
    //     stagger: 1,
    //   });
    // });
  };

  // 왜 저절로 resize 잘 안될까요
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    document.body.style.overscrollBehavior = "none";
    document.body.style.backgroundColor = "black";

    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overscrollBehavior = "auto";
      document.body.style.backgroundColor = "white";
    };
  }, []);

  const section = useRef();

  let iteration = 0;
  let seamlessLoop = null;
  let scrub = null;
  let trigger = null;

  useEffect(() => {
    const element = section.current;
    // const brands = gsap.utils.toArray(element.querySelectorAll(".brands"));
    // const cards = gsap.utils.toArray('.cards li')

    // const num = brands.length;
    // const image = element.querySelectorAll(".img");
    // const items = element.querySelectorAll(".brandName");

    const spacing = 0.1, // spacing of the cards (stagger)
      snap = gsap.utils.snap(spacing), // we'll use this to snap the playhead on the seamlessLoop
      cards = gsap.utils.toArray(".cards li");
    seamlessLoop = buildSeamlessLoop(cards, spacing);
    scrub = gsap.to(seamlessLoop, {
      // we reuse this tween to smoothly scrub the playhead on the seamlessLoop
      totalTime: 0,
      duration: 0.5,
      ease: "power3",
      paused: true,
    });
    trigger = ScrollTrigger.create({
      // trigger: section.current,
      start: 0,
      onUpdate(self) {
        if (self.progress === 1 && self.direction > 0 && !self.wrapping) {
          wrapForward(self);
        } else if (
          self.progress < 1e-5 &&
          self.direction < 0 &&
          !self.wrapping
        ) {
          wrapBackward(self);
        } else {
          scrub.vars.totalTime = snap(
            (iteration + self.progress) * seamlessLoop.duration()
          );
          scrub.invalidate().restart(); // to improve performance, we just invalidate and restart the same tween. No need for overwrites or creating a new tween on each update.
          self.wrapping = false;
        }
      },
      end: "+=3000",
      pin: ".gallery",
    });

    // MotionPathPlugin.convertToPath(element.querySelector("#path"));
    // brands.forEach((div, i) => {
    //   gsap.timeline().to(div, {
    //     motionPath: {
    //       path: element.querySelector("#path"),
    //       align: element.querySelector("#path"),
    //       alignOrigin: [0.5, 0.5],
    //       autoRotate: true,
    //       end: i * (1 / num),
    //     },
    //     duration: 0.5,
    //     stagger: 1,
    //   });
    // });

    // let proxy = { skew: 0 },
    //   skewSetter = gsap.quickSetter(
    //     element.querySelectorAll(".brands"),
    //     "skewX",
    //     "deg"
    //   ),
    //   clamp = gsap.utils.clamp(-30, 30);

    // gsap
    //   .timeline({
    //     scrollTrigger: {
    //       trigger: section.current,
    //       pin: true,
    //       scrub: 0.4,
    //       start: "top top",
    //       end: "+=5000",

    //       onUpdate: (self) => {
    //         let skew = clamp(self.getVelocity() / 800);

    //         if (Math.abs(skew) > Math.abs(proxy.skew)) {
    //           proxy.skew = skew;
    //           gsap.to(proxy, {
    //             skew: 0,
    //             duration: 1,
    //             ease: "power3",
    //             overwrite: true,
    //             onUpdate: () => skewSetter(proxy.skew),
    //           });
    //         }
    //       },
    //     },
    //   })
    //   .to(element.querySelector("#wrap"), {
    //     rotation: -360,
    //     transformOrigin: "center",
    //     duration: 1,
    //     ease: "none",
    //   });

    // let moveImg = () => {
    //   items.forEach((e1) => {
    //     image.forEach((img) => {
    //       if (img.id == e1.id) {
    //         e1.addEventListener("mouseenter", (e) => {
    //           gsap.to(img, {
    //             autoAlpha: 1,
    //             startAt: {
    //               x: e.offsetX,
    //               y:
    //                 e.screenY > 200
    //                   ? e.screenY > 500
    //                     ? e.screenY - 400
    //                     : -100
    //                   : -(e.screenY + 75),
    //             },
    //             x: 400,
    //             duration: 1,
    //           });
    //         });
    //         e1.addEventListener("mouseleave", (e) => {
    //           e1.style.color = isDsPage ? "white" : "black";
    //           e1.style.textShadow = "none";
    //           gsap.to(img, {
    //             autoAlpha: 0,
    //             x: 800,
    //             duration: 1,
    //           });
    //         });
    //         return;
    //       }
    //     });
    //   });
    // };

    // gsap
    //   .timeline({
    //     scrollTrigger: {
    //       trigger: section.current,
    //       pin: true,
    //       scrub: 0.4,
    //       start: "top top",
    //       end: "+=5000",
    //     },
    //   })
    //   .to(element.querySelector(".Images"), {
    //     ease: "slow",
    //     onStart: moveImg(),
    //   });
  }, []);

  function wrapForward(trigger) {
    // when the ScrollTrigger reaches the end, loop back to the beginning seamlessly
    iteration++;
    trigger.wrapping = true;
    trigger.scroll(1);
  }

  function wrapBackward(trigger) {
    // when the ScrollTrigger reaches the start again (in reverse), loop back to the end seamlessly
    iteration--;
    if (iteration < 0) {
      // to keep the playhead from stopping at the beginning, we jump ahead 10 iterations
      iteration = 9;
      seamlessLoop.totalTime(
        seamlessLoop.totalTime() + seamlessLoop.duration() * 10
      );
      scrub.pause(); // otherwise it may update the totalTime right before the trigger updates, making the starting value different than what we just set above.
    }
    trigger.wrapping = true;
    trigger.scroll(2800);
  }

  function scrubTo(totalTime) {
    // moves the scroll position to the place that corresponds to the totalTime value of the seamlessLoop, and wraps if necessary.
    let progress =
      (totalTime - seamlessLoop.duration() * iteration) /
      seamlessLoop.duration();
    if (progress > 1) {
      wrapForward(trigger);
    } else if (progress < 0) {
      wrapBackward(trigger);
    } else {
      trigger.scroll(trigger.start + progress * (trigger.end - trigger.start));
    }
  }

  function buildSeamlessLoop(items, spacing) {
    let overlap = Math.ceil(1 / spacing), // number of EXTRA animations on either side of the start/end to accommodate the seamless looping
      startTime = items.length * spacing + 0.5, // the time on the rawSequence at which we'll start the seamless loop
      loopTime = (items.length + overlap) * spacing + 1, // the spot at the end where we loop back to the startTime
      rawSequence = gsap.timeline({ paused: true }), // this is where all the "real" animations live
      seamlessLoop = gsap.timeline({
        // this merely scrubs the playhead of the rawSequence so that it appears to seamlessly loop
        paused: true,
        repeat: -1, // to accommodate infinite scrolling/looping
        onRepeat() {
          // works around a super rare edge case bug that's fixed GSAP 3.6.1
          this._time === this._dur && (this._tTime += this._dur - 0.01);
        },
      }),
      l = items.length + overlap * 2,
      time = 0,
      i,
      index,
      item;

    // set initial state of items
    gsap.set(items, { xPercent: 400, opacity: 0, scale: 0 });

    // now loop through and create all the animations in a staggered fashion. Remember, we must create EXTRA animations at the end to accommodate the seamless looping.
    for (i = 0; i < l; i++) {
      index = i % items.length;
      item = items[index];
      time = i * spacing;
      rawSequence
        .fromTo(
          item,
          { scale: 0, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            zIndex: 100,
            duration: 0.5,
            yoyo: true,
            repeat: 1,
            ease: "power1.in",
            immediateRender: false,
          },
          time
        )
        .fromTo(
          item,
          { xPercent: 400 },
          { xPercent: -400, duration: 1, ease: "none", immediateRender: false },
          time
        );
      i <= items.length && seamlessLoop.add("label" + i, time); // we don't really need these, but if you wanted to jump to key spots using labels, here ya go.
    }

    // here's where we set up the scrubbing of the playhead to make it appear seamless.
    rawSequence.time(startTime);
    seamlessLoop
      .to(rawSequence, {
        time: loopTime,
        duration: loopTime - startTime,
        ease: "none",
      })
      .fromTo(
        rawSequence,
        { time: overlap * spacing + 1 },
        {
          time: startTime,
          duration: startTime - (overlap * spacing + 1),
          immediateRender: false,
          ease: "none",
        }
      );
    return seamlessLoop;
  }

  useEffect(() => {
    if (section.current) {
      //console.log("scroll to");
      section.current.scrollTo(0, 5000);
    }
  }, [section]);

  return (
    <div class="gallery">
      <ul class="cards">
        <li>0</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li>5</li>
        <li>6</li>
        <li>7</li>
        <li>8</li>
        <li>9</li>
        <li>10</li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
        <li>17</li>
        <li>18</li>
        <li>19</li>
        <li>20</li>
        <li>21</li>
        <li>22</li>
        <li>23</li>
        <li>24</li>
        <li>25</li>
        <li>26</li>
        <li>27</li>
        <li>28</li>
        <li>29</li>
        <li>30</li>
      </ul>
      <div class="actions">
        <button class="prev">Prev</button>
        <button class="next">Next</button>
      </div>
    </div>
  );

  return (
    <div
      className="back"
      style={{
        background: isDsPage ? "black" : "white",
      }}
    >
      <Logo />

      <section ref={section} className="container">
        <section className="Images">
          {/* {brandDetails &&
            brandDetails.map((val, index) => (
              <img
                alt="Brand image"
                src={val.image}
                className="img"
                id={`${val.brandName
                  .replace(/ /g, "") //REMOVE WHITE SPACE
                  .replace(/['"]+/g, "")}`} //REMOVE '' AND ""
              />
            ))} */}
          {brandImageList &&
            brandImageList.map((val, index) => {
              //console.log("valval", val);
              if (val?.images?.length > 0) {
                return (
                  <img
                    alt="Brand image"
                    src={val?.images?.length > 0 ? val?.images[0] : ""}
                    className="img"
                    id={`${val.brandName
                      .replace(/ /g, "")
                      .replace(/['"]+/g, "")}`} //REMOVE '' AND ""
                  />
                );
              } else {
                return <></>;
              }
            })}
        </section>
        <div>
          <Backbutton isDsPage={isDsPage} />
        </div>
        <div id="wrap">
          <svg viewBox={`0 0 ${circleSize} ${circleSize}`}>
            <circle id="path" r={`${circleSize}`} />
          </svg>
          {/* {brandDetails &&
            brandDetails.map((val, index) => (
              <div className="brands">
                <div
                  className="brandsText"
                  key={index}
                  onClick={() =>
                    !isDsPage &&
                    history.push({
                      pathname: `/brandinfo${val.bannerType ?? "1"}`,
                      state: {
                        brandId: val.brandId,
                        brandName: val.brandName,
                      },
                    })
                  }
                >
                  <a
                    href={isDsPage && `/showroom?brandId=${val.brandId}`}
                    className="brandName"
                    id={`${val.brandName
                      .replace(/ /g, "")
                      .replace(/['"]+/g, "")}`}
                    style={{
                      color: isDsPage ? "white" : "black",
                    }}
                  >
                    {val.brandName}
                    <span className="brandsIndex"> {index} </span>
                  </a>
                </div>
              </div>
            ))} */}
          {categoryList &&
            categoryList.map((val, index) => {
              return (
                <>
                  <div className="brands">
                    <div
                      className={isDsPage ? "brandsTextWhite" : "brandsText"}
                      key={index}
                    >
                      <a
                        className="brandName"
                        id={`${val.categoryName
                          .replace(/ /g, "")
                          .replace(/['"]+/g, "")}`}
                        style={{
                          color: isDsPage ? "white" : "black",
                          cursor: "default",
                        }}
                      >
                        {val.categoryName}
                        {/* <span className="brandsIndex"> {index} </span> */}
                      </a>
                    </div>
                  </div>
                  {val.brands.map((v, i) => {
                    // bi++;
                    return (
                      <div className="brands">
                        <div
                          className={
                            isDsPage ? "brandsTextWhite" : "brandsText"
                          }
                          key={i}
                          onClick={() =>
                            !isDsPage &&
                            history.push({
                              pathname: `/brandinfo${v.bannerType ?? "1"}`,
                              state: {
                                brandId: v.brandId,
                                brandName: v.brandName,
                              },
                            })
                          }
                        >
                          <a
                            href={isDsPage && `/showroom?brandId=${v.brandId}`}
                            className={`brandName ${
                              isDsPage
                                ? "brandNameWhite__hover"
                                : "brandName__hover"
                            } `}
                            id={`${v.brandName
                              .replace(/ /g, "")
                              .replace(/['"]+/g, "")}`}
                          >
                            {/* <span className="brandsIndex">{val.categoryName}</span> */}
                            {v.brandName}
                            {/* <span className="brandsIndex">0{i + 1}</span> */}
                            {/* <span className="brandsIndex"> {bi} </span> */}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })}
        </div>
      </section>
    </div>
  );
}

export default BrandList;
