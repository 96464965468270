import React, { useRef, useEffect, useState, useMemo } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Backbutton from "../button/Backbutton";
import "./BrandList.scss";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import { throttle } from "lodash";
import Logo from "../button/Logo";

let directionChange = false;

const clampNumber = (num, a, b) =>
  Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

function BrandList({ isDsPage }) {
  let history = useHistory();
  gsap.registerPlugin(MotionPathPlugin);
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);

  // const [brandDetails, setBrandDetails] = useState();
  const [brandImageList, setBrandImageList] = useState([]);
  // let bi = 0;
  const [categoryList, setCategoryList] = useState();
  const [circleSize, setCircleSize] = useState(600);

  // const [directionChange, setDirectionChange] = useState(false);

  let circle = 0;

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getCategories`)
      .then((res) => {
        //console.log("categories--------", res.data.data);
        setCategoryList(res.data.data);
        let list = [];
        for (let i = 0; i < res.data.data.length; i++) {
          list = [...list, ...res.data.data[i].brands];
        }
        setBrandImageList(list);
        //console.log("CategoryList", res.data.data);
        //console.log("BrandImageList", list);
      });

    // await axios
    //   .get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getAllBrands`)
    //   .then((res) => {
    //     //console.log("RESPONSE : ", res);
    //     setBrandDetails([...res.data.data]);
    //   });

    // if (isMobile) setCircleSize(300);
    //console.log("isMobile: ", isMobile);
  }, []);

  const handleResize = (e) => {
    const element = section.current;
    const brands = gsap.utils.toArray(element?.querySelectorAll(".brands"));
    const num = brands.length;

    MotionPathPlugin.convertToPath(element?.querySelector("#path"));
    brands.forEach((div, i) => {
      gsap.timeline().to(div, {
        motionPath: {
          path: element?.querySelector("#path"),
          align: element?.querySelector("#path"),
          alignOrigin: [0.5, 0.5],
          autoRotate: true,
          end: i * (1 / num),
        },
        duration: 0.5,
        stagger: 1,
      });
    });
  };

  // 왜 저절로 resize 잘 안될까요
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   document.body.style.overscrollBehavior = "none";
  //   document.body.style.backgroundColor = "black";

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //     document.body.style.overscrollBehavior = "auto";
  //     document.body.style.backgroundColor = "white";
  //   };
  // }, []);

  const section = useRef();

  let scrollT = null;
  let wheelTriger = null;

  useEffect(() => {
    const element = section.current;
    const brands = gsap.utils.toArray(element.querySelectorAll(".brands"));
    const num = brands.length;
    const image = element.querySelectorAll(".img");
    const items = element.querySelectorAll(".brandName");

    MotionPathPlugin.convertToPath(element.querySelector("#path"));
    brands.forEach((div, i) => {
      gsap.timeline().to(div, {
        motionPath: {
          path: element.querySelector("#path"),
          align: element.querySelector("#path"),
          alignOrigin: [0.5, 0.5],
          autoRotate: true,
          end: i * (1 / num),
        },
        duration: 0.5,
        stagger: 1,
      });
    });

    // brands.slice(0, 8).forEach((div, i) => {
    //   gsap.timeline().to(div, {
    //     motionPath: {
    //       path: element.querySelector("#path"),
    //       align: element.querySelector("#path"),
    //       alignOrigin: [0.5, 0.5],
    //       autoRotate: true,
    //       end: i * (1 / 24),
    //     },
    //     duration: 0.5,
    //     stagger: 1,
    //   });
    // });

    let proxy = { skew: 0 },
      skewSetter = gsap.quickSetter(
        element.querySelectorAll(".brands"),
        "skewX",
        "deg"
      ),
      clamp = gsap.utils.clamp(-30, 30);

    scrollT = gsap.timeline().to(element.querySelector("#wrap"), {
      rotation: -360,
      transformOrigin: "center",
      duration: 1,
      // repeat: -1,
      ease: "none",
    });

    wheelTriger = ScrollTrigger.create({
      // animation: scrollT,
      trigger: section.current,
      pin: true,
      scrub: 0.4,
      start: "top top",
      end: "+=5000",
      onUpdate: (self) => {
        const velocity = self.getVelocity();
        let skew = clamp(velocity / 1000);
        if (Math.abs(velocity) < 20000) {
          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            proxy.skew = skew;
            gsap.to(proxy, {
              skew: 0,
              duration: 1,
              ease: "power3",
              overwrite: true,
              onUpdate: () => skewSetter(proxy.skew),
            });
          }
        }
        let newVelocity = clampNumber(8000, 0, Math.abs(velocity));

        // //console.log(self.progress, self.direction);
        if (self.progress > 1 - 0.1 && self.direction > 0 && !self.wrapping) {
          if (!self.wrapping) {
            // //console.log("end");
            // setDirectionChange(true);
            // directionChange = true;
            wrapForward(self, newVelocity);
          }
        } else if (
          self.progress < 0.1 &&
          self.direction < 0 &&
          !self.wrapping
        ) {
          if (!self.wrapping) {
            // //console.log("start");
            // setDirectionChange(true);
            // directionChange = true;
            wrapBackward(self, newVelocity);
          }
        } else {
          self.wrapping = false;
          // //console.log("moving", self.direction);
          // //console.log("velocity", velocity);
          // //console.log("direction", directionChange);
          if (Math.abs(velocity) < 20000) {
            if (self.direction > 0) {
              // 아래로 돌리기~

              if (!directionChange)
                gsap.to(document.querySelector("#wrap"), {
                  rotation: `-=${(newVelocity / 8000) * 100}`,
                  transformOrigin: "center",
                  duration: isMobile ? 0.2 : 0.8,
                  // repeat: -1,
                  ease: "power4",
                  // wrapping: true,
                  overwrite: true,
                });
              else directionChange = false;
              self.wrapping = false;
            } else {
              // 위로 돌리기~
              if (!directionChange)
                gsap.to(document.querySelector("#wrap"), {
                  rotation: `+=${(newVelocity / 8000) * 100}`,
                  transformOrigin: "center",
                  duration: isMobile ? 0.2 : 0.8,
                  // repeat: -1,
                  ease: "power4",
                  // wrapping: true,
                  overwrite: true,
                });
              else directionChange = false;
              self.wrapping = false;
            }
          }
        }
      },
      onScrubComplete: () => {},
    });

    let moveImg = () => {
      items.forEach((e1) => {
        image.forEach((img) => {
          console.log("img.id", img.id, "e1.id", e1.id);
          if (img.id == e1.id) {
            e1.addEventListener("mouseenter", (e) => {
              gsap.to(img, {
                autoAlpha: 1,
                startAt: {
                  x: e.offsetX,
                  y:
                    e.screenY > 200
                      ? e.screenY > 500
                        ? e.screenY - 400
                        : -100
                      : -(e.screenY + 75),
                },
                x: 400,
                duration: 1,
              });
            });
            e1.addEventListener("mouseleave", (e) => {
              e1.style.color = isDsPage ? "white" : "black";
              e1.style.textShadow = "none";
              gsap.to(img, {
                autoAlpha: 0,
                x: 800,
                duration: 1,
              });
            });
            return;
          }
        });
      });
    };

    gsap
      .timeline({
        scrollTrigger: {
          trigger: section.current,
          pin: true,
          scrub: 0.4,
          start: "top top",
          end: "+=5000",
        },
      })
      .to(element.querySelector(".Images"), {
        ease: "slow",
        onStart: moveImg(),
      });

    wheelTriger.scroll(501);
    return () => {
      if (wheelTriger) {
        //console.log("kill");
        wheelTriger.disable();
      }
    };
  });

  function wrapForward(trigger, newVelocity) {
    // when the ScrollTrigger reaches the end, loop back to the beginning seamlessly
    // wheelTriger.wrapping = true;
    trigger.wrapping = true;
    wheelTriger.scroll(510);

    if (isMobile)
      gsap.to(document.querySelector("#wrap"), {
        // rotation: `-=100`,
        rotation: `-=${(newVelocity / 8000) * 250}`,
        transformOrigin: "center",
        duration: 1,
        // repeat: -1,
        ease: "power4",
        // wrapping: true,
        overwrite: true,
      });

    // if (wheelTriger)
    //   gsap.to(document.querySelector("#wrap"), {
    //     rotation: `-=50`,
    //     transformOrigin: "center",
    //     duration: 1,
    //     // repeat: -1,
    //     ease: "power4",
    //     wrapping: true,
    //     overwrite: "preexisting",
    //   });
  }

  function wrapBackward(trigger, newVelocity) {
    // when the ScrollTrigger reaches the start again (in reverse), loop back to the end seamlessly
    // iteration--;
    // if (iteration < 0) { // to keep the playhead from stopping at the beginning, we jump ahead 10 iterations
    //   iteration = 9;
    //   seamlessLoop.totalTime(seamlessLoop.totalTime() + seamlessLoop.duration() * 10);
    //   scrub.pause(); // otherwise it may update the totalTime right before the trigger updates, making the starting value different than what we just set above.
    // }
    // wheelTriger.wrapping = true;
    trigger.wrapping = true;
    wheelTriger.scroll(4490);

    if (isMobile)
      gsap.to(document.querySelector("#wrap"), {
        // rotation: `+=100`,
        rotation: `+=${(newVelocity / 8000) * 250}`,
        transformOrigin: "center",
        duration: 1,
        // repeat: -1,
        ease: "power4",
        // wrapping: true,
        overwrite: true,
      });

    // if (wheelTriger)
    //   gsap.to(document.querySelector("#wrap"), {
    //     rotation: `+=50`,
    //     transformOrigin: "center",
    //     duration: 1,
    //     // repeat: -1,
    //     ease: "power4",
    //     wrapping: true,
    //     overwrite: "preexisting",
    //   });
  }

  // let rotate = 0;

  // 이건 너무 끊겨요
  // const throttledScroll = useMemo(
  //   () =>
  //     throttle(() => {
  //       //console.log("hi");
  //       if (!section.current) return;

  //       var el = section.current.querySelector("#wrap");
  //       el.style.transform =
  //         "translate(-85%, -50%) rotate(" + (rotate + 5) + "deg)";
  //       rotate += 5;
  //     }, 100),
  //   []
  // );

  // 이건 보류...
  // const scrollRotate = () => {
  //   if (!section.current) return;

  //   var el = section.current.querySelector("#wrap");
  //   el.style.transform =
  //     "translate(-85%, -50%) rotate(" + (rotate + 1) + "deg)";
  //   rotate += 1;
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", scrollRotate);
  //   return () => {
  //     window.removeEventListener("scroll", scrollRotate);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (section.current) {
  //     //console.log("scroll to");
  //     section.current.scrollTo(0, 5000);
  //   }
  // }, [section]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //console.log(e);
    if (bottom) {
      //console.log("bottom");
    }
  };

  return (
    <div
      className="back"
      style={{
        background: isDsPage ? "black" : "white",
        // backgroundColor: "black",
      }}
    >
      <Logo />

      <section ref={section} className="container">
        <section className="Images">
          {/* {brandDetails &&
            brandDetails.map((val, index) => (
              <img
                alt="Brand image"
                src={val.image}
                className="img"
                id={`${val.brandName
                  .replace(/ /g, "") //REMOVE WHITE SPACE
                  .replace(/['"]+/g, "")}`} //REMOVE '' AND ""
              />
            ))} */}
          {brandImageList &&
            brandImageList.map((val, index) => {
              //console.log("valval", val);
              if (val?.images?.length > 0) {
                // console.log("val", val);
                return (
                  <img
                    alt="Brand image"
                    src={val?.images?.length > 0 ? val?.images[0] : ""}
                    className="img"
                    // id={`${val.brandName
                    //   .replace(/ /g, "")
                    //   .replace(/['"]+/g, "")}`} //REMOVE '' AND ""
                    id={val.brandId}
                  />
                );
              } else {
                return <></>;
              }
            })}
        </section>
        <div>
          <Backbutton isDsPage={isDsPage} isIndex />
        </div>
        <div id="wrap">
          <svg viewBox={`0 0 ${circleSize} ${circleSize}`}>
            <circle id="path" r={`${circleSize}`} />
          </svg>
          {/* {brandDetails &&
            brandDetails.map((val, index) => (
              <div className="brands">
                <div
                  className="brandsText"
                  key={index}
                  onClick={() =>
                    !isDsPage &&
                    history.push({
                      pathname: `/brandinfo${val.bannerType ?? "1"}`,
                      state: {
                        brandId: val.brandId,
                        brandName: val.brandName,
                      },
                    })
                  }
                >
                  <a
                    href={isDsPage && `/showroom?brandId=${val.brandId}`}
                    className="brandName"
                    id={`${val.brandName
                      .replace(/ /g, "")
                      .replace(/['"]+/g, "")}`}
                    style={{
                      color: isDsPage ? "white" : "black",
                    }}
                  >
                    {val.brandName}
                    <span className="brandsIndex"> {index} </span>
                  </a>
                </div>
              </div>
            ))} */}
          {categoryList &&
            categoryList.map((val, index) => {
              return (
                <>
                  <div className="brands">
                    <div
                      className={isDsPage ? "brandsTextWhite" : "brandsText"}
                      key={index}
                    >
                      <a
                        className="brandName"
                        id={`${val.categoryName
                          .replace(/ /g, "")
                          .replace(/['"]+/g, "")}`}
                        style={{
                          color: isDsPage ? "white" : "black",
                          cursor: "default",
                        }}
                      >
                        {val.categoryName}
                        {/* <span className="brandsIndex"> {index} </span> */}
                      </a>
                    </div>
                  </div>
                  {val.brands.map((v, i) => {
                    // bi++;
                    return (
                      <div className="brands">
                        <div
                          className={
                            isDsPage ? "brandsTextWhite" : "brandsText"
                          }
                          key={i}
                          onClick={() =>
                            !isDsPage &&
                            history.push({
                              pathname: `/brandinfo${v.bannerType ?? "1"}/${
                                v.brandId
                              }/${v.brandName}`,
                              state: {
                                brandId: v.brandId,
                                brandName: v.brandName,
                                brandColor: v.brandColor,
                                isIndex: true,
                              },
                            })
                          }
                        >
                          <a
                            href={isDsPage && `/showroom?brandId=${v.brandId}`}
                            className={`brandName ${
                              isDsPage
                                ? "brandNameWhite__hover"
                                : "brandName__hover"
                            } `}
                            // id={`${v.brandName
                            //   .replace(/ /g, "")
                            //   .replace(/['"]+/g, "")}`}
                            id={v.brandId}
                          >
                            {/* <span className="brandsIndex">{val.categoryName}</span> */}
                            {v.brandName}
                            {/* <span className="brandsIndex">0{i + 1}</span> */}
                            {/* <span className="brandsIndex"> {bi} </span> */}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })}
        </div>
      </section>
    </div>
  );
}

export default BrandList;
