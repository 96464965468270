import Parallax from "parallax-js";
import "./BrandMainBanner.scss";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Animated } from "react-animated-css";

function WallTwo(props) {
  const { isBanner } = props;
  const containerRef = useRef(null);
  const [brandDetails, setBrandDetails] = useState(null);
  let history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [randDepth, setRandDepth] = useState([0.2, 0.2]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let randValue = [];
    for (let i = 0; i < 2; i++) {
      randValue.push(Math.random() * (0.7 - 0.3) + 0.3);
    }
    setRandDepth(randValue);
  }, []);

  let parallaxInstances = [null, null];

  useEffect(() => {
    var scene3 = document.getElementsByClassName("photos2");
    // for (let i = 0; i < scene3.length; i++) {
    //   var parallaxInstance = new Parallax(scene3[i], {
    //     relativeInput: true,
    //   });
    //   parallaxInstance.friction(0.2, 0.2);
    // }
    // let parallaxInstances = Array.from(
    //   { length: scene3.length },
    //   (i) => (i = null)
    // );

    for (let i = 0; i < scene3.length; i++) {
      parallaxInstances[i] = new Parallax(scene3[i], {
        relativeInput: true,
      });
      parallaxInstances[i].friction(0.2, 0.2);
    }
    return () => {
      var scene3 = document.getElementsByClassName("photos2");

      for (let i = 0; i < scene3.length; i++) {
        if (parallaxInstances[i]) {
          // //console.log("destroy");
          parallaxInstances[i].destroy();
          parallaxInstances[i] = null;
        }
      }
    };
  }, [brandDetails]);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    // //console.log("Wall Two entry: ", entry.isIntersecting);
    setIsVisible(entry.isIntersecting);
  };
  const options = {
    root: null,
    rootMargin: isMobile ? "-150px 0px 0px 0px" : "0px",
    threshold: isMobile ? 0.5 : 0.3,
  };

  const onClickBanner = (e) => {
    if (!isBanner) {
      props.setVisitBanner(brandDetails.brandId);
      history.push({
        pathname: `/brandinfo2/${brandDetails.brandId}/${brandDetails.brandName}`,
        state: {
          brandId: brandDetails.brandId,
          brandName: brandDetails.brandName,
          nextId: props.nextId,
          brandColor: brandDetails.brandColor,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.data?.brandName) {
      setBrandDetails(props?.data);
    }
  }, [props]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, options]);

  useEffect(() => {
    if (isVisible && props?.setSelectBrand) {
      props?.setSelectBrand(brandDetails?.brandId);
    }
  }, [isVisible]);

  const handleResize = (e) => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ minHeight: (window.innerHeight * 2) / 3 }} ref={containerRef}>
      <h2
        onClick={() => {
          if (!isBanner) {
            props.setVisitBanner(brandDetails.brandId);
            history.push({
              pathname: `/brandinfo2/${brandDetails.brandId}/${brandDetails.brandName}`,
              state: {
                brandId: brandDetails.brandId,
                brandName: brandDetails.brandName,
                brandColor: brandDetails.brandColor,
                nextId: props.nextId,
              },
            });
          }
        }}
        className="ideal_showroom cursor"
        style={{
          ...(isBanner && {
            position: !props.backVisible ? "fixed" : "sticky",
          }),
          // ...(props.backVisible && { display: "none" }),
        }}
      >
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={isBanner ? !props.backVisible : isVisible}
        >
          {brandDetails?.brandName}
          {isBanner ? (
            <>
              <div style={{ fontSize: "2vw", lineHeight: 1, marginLeft: 6 }}>
                NEXT BRAND
              </div>
            </>
          ) : null}
        </Animated>
      </h2>

      <div
        className="wallTwo"
        style={{ margin: 0, display: "block", width: "100%" }}
      >
        {brandDetails?.brandBannerImages?.length > 0 && (
          <div className="wallTwo_first" onClick={onClickBanner}>
            <a className="sixth photos photos2" data-relative-input="true">
              {brandDetails?.brandBannerImages[0]?.substring(
                brandDetails?.brandBannerImages[0]?.lastIndexOf(".") + 1
              ) == "mp4" ? (
                <video
                  playsinline="playsinline"
                  onClick={onClickBanner}
                  // width="100%"
                  alt="brandInfo Image "
                  // data-depth="0.6"
                  data-depth={randDepth[0]}
                  muted
                  autoPlay
                  loop
                  className="cursor"
                >
                  <source
                    src={brandDetails?.brandBannerImages[0]}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  // data-depth="0.6"
                  data-depth={randDepth[0]}
                  className="cursor"
                  onClick={onClickBanner}
                  src={brandDetails?.brandBannerImages[0]}
                />
              )}
            </a>
          </div>
        )}
        {brandDetails?.brandBannerImages?.length > 1 && (
          <div className="wallTwo_second" onClick={onClickBanner}>
            <a className="sixth photos photos2" data-relative-input="true">
              {brandDetails?.brandBannerImages[1]?.substring(
                brandDetails?.brandBannerImages[1]?.lastIndexOf(".") + 1
              ) == "mp4" ? (
                <video
                  playsinline="playsinline"
                  onClick={onClickBanner}
                  // width="100%"
                  alt="brandInfo Image"
                  // data-depth="0.3"
                  data-depth={randDepth[1]}
                  muted
                  autoPlay
                  loop
                  className="cursor"
                >
                  <source
                    src={brandDetails?.brandBannerImages[1]}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  // data-depth="0.3"
                  data-depth={randDepth[1]}
                  className="cursor"
                  onClick={onClickBanner}
                  src={brandDetails?.brandBannerImages[1]}
                />
              )}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default WallTwo;
