import { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import heading from "../../../assets/ideal-logo.svg";
import Logo from "../../button/Logo";
import TokenVerification from "../TokenVerification/TokenVerification";

const LoginCompleted = () => {
  const history = useHistory();

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   history.push("/");
    // }, 2000);
  }, []);
  return (
    <div className="login-completed">
      <Logo />
      <div className="login-completed__container">
        <div className="login-completed__title">
          <img src={heading} style={{ filter: "invert(1)", height: 30 }} />
          <span className="login-completed__subheading">DIGITAL SHOWROOM</span>
        </div>
        <span>
          Welcome <br />
          Access Granted
        </span>
      </div>
    </div>
  );
};

export default LoginCompleted;
