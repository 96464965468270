import { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import Close from "../../assets/close.svg";
import "react-image-lightbox/style.css";
import "./Showroom.scss";

function ProductCut({ productDetails, brandDetails }) {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [state, setState] = useState(false);

  return (
    // <div className="ProductCutDetails">
    <>
      {productDetails?.map((products, i) => (
        <>
          <a
            className="ProductCut"
            onClick={() => {
              if (products) {
                console.log("click index: ", index);
                setState(true);
                setIndex(0);
                setImages(products.images);
              }
            }}
          >
            {products && (
              <>
                <div className="ProductCutImage">
                  <img
                    src={products?.images[0]}
                    style={{ width: "100%" }}
                  ></img>
                  {/* {products?.images[1] && <img src={products?.images[1]}></img>} */}
                </div>
                <ul className="ProductCutText">
                  <li>{brandDetails?.brandName}</li>
                  <li>{products?.productName}</li>
                  <li>{products?.productCode}</li>
                  <li>{"$" + products?.price}</li>
                </ul>
              </>
            )}
          </a>
        </>
      ))}
      {state && (
        <Lightbox
          mainSrc={images[index]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          nextSrc={images[(index + 1) % images.length]}
          onMovePrevRequest={() => {
            setIndex((index - 1 + images.length) % images.length);
          }}
          onMoveNextRequest={() => {
            setIndex((index + 1) % images.length);
          }}
          toolbarButtons={[
            <div className="closeButton">
              <img
                src={Close}
                onClick={() => {
                  setState(false);
                  setImages([]);
                }}
              />
            </div>,
          ]}
        />
      )}
    </>
    // </div>
  );
}

export default ProductCut;
