import React, { useState, useEffect } from "react";
import "./Backbutton.scss";
import gsap from "gsap";
import { useHistory } from "react-router";
import { Animated } from "react-animated-css";

function TopButtons(props) {
  let history = useHistory();
  // gsap.set('.top_effect',{x:'-100%'},3)

  // var lines = gsap.timeline({repeat:0}).to('.top_effect',1,{x:'0%'})

  return (
    // <div className="homepage_header mt-5">
    //     {/* <button className="homepage_button brand_button top_origin">BRANDS</button> */}
    //     {/* <button className="homepage_button brand_button top_effect">BRANDS</button>
    //     <button className="homepage_button about_button top_origin">ABOUT</button>  */}
    //     <button className="homepage_button about_button top_effect">ABOUT</button>
    // </div>
    <div>
      <div className="aboutButton" onClick={() => history.push("about-main")}>
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={props.show}
        >
          <button className="about_origin">ABOUT</button>
          <button className="about_effect">ABOUT</button>
        </Animated>
      </div>
      <div className="brandButton" onClick={() => history.push("index")}>
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={props.show}
        >
          <button className="brand_origin">BRANDS</button>
          <button className="brand_effect">BRANDS</button>
        </Animated>
      </div>
    </div>
  );
}

export default TopButtons;
