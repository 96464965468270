import React, { Component } from "react";
import Logo from "../button/Logo";
import "./about.scss";
import About_footer_links from "./About_footer_links";
import Footer from "./Footer";
import Backbutton from "../button/Backbutton";
import DISTRIBUTEIMG from "./images/LICENSING_DISTRIBUTION.jpg";

export class Brand_Distribution extends Component {
  render() {
    return (
      <>
        <Logo />
        <Backbutton isDsPage />
        <div className="about_container first_child">
          <div className="about_image">
            <img src={DISTRIBUTEIMG} />
          </div>
          <div>
            <div className="about_textContainer">
              <div className="about_title">LICENSING & DISTRIBUTION</div>
              <div className="about_text" style={{ maxWidth: 700 }}>
                IDEAL Performs as a distributor to introduce the international
                brands to Korean fashion market after a thorough analysis of
                local and brands' needs to draw cuztomized strategies.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about_container">
          <div>
            <div className="about_textContainer">
              <div className="about_title"></div>
              <div className="about_text">
                In addition to Distribution, IDEAL
              </div>
            </div>
          </div>
          <div className="about_image">
            <img src="/Images/About_pics/Rectangle 572.png" />
          </div>
        </div> */}
        <About_footer_links />
        <Footer />
      </>
    );
  }
}

export default Brand_Distribution;
