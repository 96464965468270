import { useState, useEffect } from "react";
import BottomButtons from "../../button/BottomButtons";
import Logo from "../../button/Logo";
import TopButtons from "../../button/TopButtons";
import "./MainLogos.scss";

function Mainlogos({ move }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(move);
  }, [move]);

  return (
    <div>
      <Logo show={show} />
      <TopButtons show={show} />
      <BottomButtons show={show} />
    </div>
  );
}

export default Mainlogos;
