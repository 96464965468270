import axios from "axios";
import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router";
import TokenVerification from "../authentication/TokenVerification/TokenVerification";
import Backbutton from "../button/Backbutton";
import Logo from "../button/Logo";

const Calendar = ({ loadUrlPath, loggedInUser }) => {
  const [calendarDetails, setCalenderDetails] = useState();
  const [categoryList, setCategoryList] = useState();

  const location = useLocation();
  const history = useHistory();

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/brand/getCategories`)
      .then((res) => {
        //console.log("categories--------", res.data.data);
        setCategoryList(res.data.data);
      });

    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/calendar/getCalendarDetail`
      )
      .then((res) => {
        // //console.log(res.data.data, "-----calender data------");
        setCalenderDetails(res.data.data);
      })
      .then(() => {
        TokenVerification().then((result) => {
          //console.log("-----result----", result);
          // if (!result) history.replace("/login");
        });
      })
      .catch((err) => {
        //console.log("error : ", err);
      });
  }, []);

  useEffect(() => {
    //console.log("calendarDetails : ", calendarDetails);
  }, [calendarDetails]);

  const handleLogin = () => {
    history.push("/login");
    loadUrlPath(location.pathname);
  };

  const handleSignup = () => {
    history.push("/signup");
    loadUrlPath(location.pathname);
  };

  // Calendly styles and settings
  // const calendlyUrl = "https://calendly.com/idealshowroom";
  const calendlyUrl = "https://calendly.com/idealshowroom";
  const calendlyStyle = {
    width: "100%",
    height: 600,
  };
  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "#00a2ff",
    textColor: "#000000",
  };

  // Prefilled data while confirming appointment in Calendly
  const prefill = {
    email: loggedInUser?.companyEmail,
    name: loggedInUser?.name,
  };

  function handleMenuClick(e) {
    history.push(`/Showroom?brandId=${e}`);
  }

  return (
    <div className="calendar">
      <div className="calendar__header">
        <Logo />
      </div>

      <Backbutton isDsPage isCalendar />

      <div className="calendar__container">
        <div className="calendar__left">
          <div className="calendar__left-container">
            <div className="calendar__title">
              {calendarDetails?.title.map((t) => (
                <h1>{t}</h1>
              ))}
              {calendarDetails?.details.map((detail) => (
                <p>{detail}</p>
              ))}
            </div>

            <div className="calendar__options">
              {categoryList?.map((category) => (
                <div className="calendar__option">
                  <div className="calendar__option-title">
                    <h4>+ {category?.categoryName}</h4>
                  </div>
                  <ul className="calendar__option-options">
                    {category?.brands.map((brand) => (
                      <li
                        className="cursor"
                        onClick={() => handleMenuClick(brand.brandId)}
                      >
                        {brand.brandName}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="calendar__right">
          {localStorage.getItem("authToken") === null ? (
            <div className="calendar__right-container">
              <div className="calendar__login-title">
                <h2>
                  LOG IN TO BOOK A <br />
                  LIVE STREAMING APPOINTMENT
                </h2>
              </div>
              <div className="calendar__login-buttons">
                <button onClick={handleLogin}>LOG IN</button>
                <button onClick={handleSignup}>SIGN UP</button>
              </div>
            </div>
          ) : (
            <div className="calendar__wrapper">
              <InlineWidget
                styles={calendlyStyle}
                pageSettings={pageSettings}
                prefill={prefill}
                url={calendlyUrl}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
