import { isMobile } from "react-device-detect";

const Footer = () => {
  return (
    <div>
      {/* <hr className="footer__hr" /> */}

      <div
        className="footer"
        style={{
          paddingTop: 24,
          ...(isMobile && { display: "flex", paddingTop: 0 }),
        }}
      >
        <div className="right-align" style={{ ...(isMobile && { display: "flex", flex: 1 }) }}>
          <div style={{ justifyContent: "flex-start" }}>
            <p>iDEAL SHOWROOM </p>
            <p>2F, 15, Nonhyeon-ro 158-gil,</p>
          </div>
          <div style={{ marginRight: 40, justifyContent: "flex-start" }}>
            <p>Gangnam-gu, Seoul,</p>
            <p>Republic of Korea. 06025</p>
          </div>
        </div>
        <div className="right-align">
          <div style={{ justifyContent: "flex-start" }}>
            <p>
              <img src="/Images/About_pics/Phone.svg" />
              +82 70. 8898. 8681
            </p>
            <p>
              <a href="https://www.instagram.com/idealpeople_official/" target="_blank">
                <img src="/Images/About_pics/Instagram.svg" />
                INSTAGRAM
              </a>
            </p>
            <p>
              <a href="mailto:general@idealppl.com" target="_blank">
                <img src="/Images/About_pics/Email.svg" />E MAIL
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* <hr style={{ height: 1, background: "black", marginBottom: 8 }} /> */}
      <hr className="footer__hr" style={{ marginBottom: 8 }} />
      <p className="footer__bottomCaption">2021 iDEAL co. Ltd</p>
    </div>
  );
};

export default Footer;
