import React, { Component } from "react";
import Logo from "../button/Logo";
import "./about.scss";
import About_footer_links from "./About_footer_links";
import Footer from "./Footer";
import Backbutton from "../button/Backbutton";

import INTERIMG from "./images/INTERNATIONAL_WHOLESALE.jpg";

export class International_wholesale extends Component {
  render() {
    return (
      <>
        <Backbutton isDsPage />
        <Logo />
        <div className="about_container first_child">
          <div className="about_image">
            <img src={INTERIMG} />
          </div>
          <div>
            <div className="about_textContainer">
              <div className="about_title">International wholesale</div>
              <div className="about_text">
                IDEAL started showroom business in New York from 2008, and as of
                now, we have supported over 100 brands to make inroads into
                global markets and have successfully put it through.
                <br />
                <br />
                Before the Covid-19 outbreak, IDEAL had operated showrooms about
                10 times a year, including Paris, New York, and Seoul Fashion
                Week, and in response to Covid-19 situation, we have operated
                Digital showroom 4 times a year in line with the Paris Fashion
                Week schedule.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about_container">
          <div className="about_image">
            <img src="/Images/About_pics/Rectangle 572.png" />
          </div>
          <div>
            <div className="about_textContainer">
              <div className="about_title"></div>
              <div className="about_text">
                Before the Covid-19 outbreak, IDEAL had operated showrooms about
                10 times a year, including Paris, New York, and Seoul Fashion
                Week, and in response to Covid-19 situation, we have operated
                Digital showroom 4 times a year in line with the Paris Fashion
                Week schedule.
              </div>
            </div>
          </div>
        </div> */}
        <About_footer_links />
        <Footer />
      </>
    );
  }
}

export default International_wholesale;
