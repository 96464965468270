const dev = {
  ideal_api_url: `${process.env.REACT_APP_BACKEND_API}/api/company`,
};

// Put production api url below!

const prod = {
  ideal_api_url: `${process.env.REACT_APP_BACKEND_API}/api/company`,
};

const config = process.env.REACT_APP_STAGE == "dev" ? dev : prod;

export default config;
