import { gsap } from "gsap";
import "./BrandMainBanner.scss";
import { useEffect, useRef, useState } from "react";
import Parallax from "parallax-js";
import WallOne from "./WallOne";
import WallTwo from "./WallTwo";
import WallThree from "./WallThree";
import axios from "axios";
import { AnimatedBg, Transition } from "scroll-background";

function BrandMainBanner(props) {
  const { datas, setDatas } = props;
  const { selectBrand, setSelectBrand } = props;
  const [wallOne, setWallOne] = useState([]);
  const [wallTwo, setWallTwo] = useState([]);
  const [wallThree, setWallThree] = useState([]);
  // const [datas, setDatas] = useState([]);
  // const [selectBrand, setSelectBrand] = useState("");
  const [ScrollY, setScrollY] = useState(0);

  useEffect(() => {
    //console.log("selectBrand", selectBrand, setBgColor(selectBrand));
  }, [selectBrand]);

  useEffect(() => {
    getBrandBannerImages2();
  }, []);

  // useEffect(() => {
  //   setBgWhite();
  // }, [window.scrollY]);

  const getBrandBannerImages2 = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/brand/getBrandBannerImages`
      )
      .then((res) => {
        setDatas(
          res.data.data.filter((value) => value.brandBannerImages?.length > 0)
        );
      })
      .catch((err) => {
        //console.log("error : ", err);
      });
  };

  const setBgColor = (brand) => {
    switch (brand) {
      case "MINJUKIM":
        return "#E3F8FF";
      case "thisisneverthat":
        return "#E8FFE8";
      case "RECTO":
        return "#EED8D8";
      case "SOMEIT":
        return "#E3F8FF";
      case "YOUNG & SANG":
        return "#E8FFE8";
      case "YOUNG & SANG":
        return "#EED8D8";
      case "AMOMENTO":
        return "#E3F8FF";
      case "COMME SE-A":
        return "#E8FFE8";
      case "gu_de":
        return "#EED8D8";
      case "isabella ētou":
        return "#E3F8FF";
      case "PROJEKT PRODUKT":
        return "#E8FFE8";
      case "LOEUVRE":
        return "#EED8D8";
      case "YY by YUUL YIE":
        return "#E3F8FF";
      case "KIMHEKIM":
        return "#E8FFE8";
      case "PORTRAIT REPORT":
        return "#EED8D8";
      case "KIJUN":
        return "#E3F8FF";
      case "J KOO":
        return "#E8FFE8";
      case "white":
        return "white";
    }
  };

  const setBgWhite = () => {
    // setScrollY(window.pageYOffset);
    if (window.pageYOffset < 100) {
      setSelectBrand("white");
    }
  };

  const setVisitBanner = (bannerId) => {
    localStorage.setItem("visitBanner", bannerId);
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", setBgWhite);
    };
    watch();
    return () => {
      window.removeEventListener("scroll", setBgWhite);
    };
  });

  useEffect(() => {
    if (datas) {
      const visitBanner = localStorage.getItem("visitBanner");
      console.log("visitBanner", visitBanner);
      if (visitBanner) {
        const bannerDiv = document.getElementsByClassName(visitBanner);
        console.log("bannerDiv", bannerDiv);
        if (bannerDiv.length > 0) {
          setSelectBrand(visitBanner);
          bannerDiv[0].scrollIntoView();
          localStorage.removeItem("visitBanner");
        }
      }
    }
  }, [datas]);

  return (
    <div
      className={`BrandMainBanner_01 `}
      // style={{ backgroundColor: `${setBgColor(selectBrand)}` }}
      style={{ paddingBottom: 300 }}
    >
      {/* <AnimatedBg> */}

      {datas
        .filter((value) => value.brandBannerImages?.length > 0)
        .map((value, index) => {
          // //console.log(value, "FF");
          if (value.bannerType == 1) {
            return (
              <div className={value.brandId}>
                <WallOne
                  load={props.load}
                  setSelectBrand={setSelectBrand}
                  nextId={
                    index >= datas.length - 1 ? "" : datas[index + 1].brandId
                  }
                  data={value}
                  setVisitBanner={setVisitBanner}
                />
              </div>
            );
          } else if (value.bannerType == 2) {
            return (
              <div className={value.brandId}>
                <WallTwo
                  load={props.load}
                  setSelectBrand={setSelectBrand}
                  nextId={
                    index >= datas.length - 1 ? "" : datas[index + 1].brandId
                  }
                  data={value}
                  setVisitBanner={setVisitBanner}
                />
              </div>
            );
          } else {
            return (
              <div className={value.brandId}>
                <WallThree
                  load={props.load}
                  setSelectBrand={setSelectBrand}
                  nextId={
                    index >= datas.length - 1 ? "" : datas[index + 1].brandId
                  }
                  data={value}
                  setVisitBanner={setVisitBanner}
                />
              </div>
            );
          }
        })}
      {/* </AnimatedBg> */}
    </div>
  );
}

export default BrandMainBanner;
