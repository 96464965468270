import React, { Component } from "react";
import Logo from "../button/Logo";
import "./about.scss";
import About_footer_links from "./About_footer_links";
import Footer from "./Footer";
import Backbutton from "../button/Backbutton";
import AboutImg from "./images/ABOUT_US.jpg";
import { isMobile } from "react-device-detect";

export class About extends Component {
  render() {
    return (
      <>
        <Logo />
        <Backbutton isDsPage />
        <div className="about_container first_child">
          {isMobile && (
            <div className="about_image">
              <img src={AboutImg} />
            </div>
          )}
          <div>
            <div className="about_textContainer">
              <div className="about_title">ABOUT US</div>
              <div className="about_text" style={{ maxWidth: 700 }}>
                IDEAL is a Seoul based fashion sales and marketing agency, global approach with
                specific market knowledge and solutions. We supports designer brands make inroads
                into global markets, contributes to their sales growth, and has expertise in
                planning and managing fashion shows and fashion events.
                <br />
                <br />
                Leading the showroom industry by proposing a new business platform when the showroom
                business has not been settled in the Korean fashion industry.
                <br /> Starting in New York, we have been working in fashion markets around the
                world such as Paris, Milan and London working with over 300 brands, stores, and
                major global showrooms for over 10 years.
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="about_image">
              <img src={AboutImg} />
            </div>
          )}
        </div>
        {/* <div className="about_container">
          <div className="about_image">
            <img src="/Images/About_pics/Rectangle 572.png" />
          </div>
          <div className="about_textContainer">
            <div className="about_title"></div>
            <div className="about_text">
              Leading the showroom industry by proposing a new business platform
              when the showroom business has not been settled in the Korean
              fashion industry.
              <br /> Starting in New York, we have been working in fashion
              markets around the world such as Paris, Milan and London working
              with over 300 brands, stores, and major global showrooms for over
              10 years.
            </div>
          </div>
        </div> */}
        <About_footer_links />
        <Footer />
      </>
    );
  }
}

export default About;
